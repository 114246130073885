import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
  Box,
  Tooltip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useApp } from "../../hooks/useApp";
import AddOrUpdateUserModal from "./AddOrUpdateUserModal";
import DeleteModal from "./DeleteModal";
import UpdateUserPassModal from "./UpdateUserPassModal";
import FancyTablePagination from "./FancyTablePagination";
import ResourceAccess from "../security/ResourceAccess";
import { useTranslation } from "react-i18next";
import FancyTooltip from "./FancyTooltip";
import { activateUser, createUser, deleteRequest, getRequestUser, updatePassRequest, updateUser } from "../../services/users/UserService";
import { properties } from "../../utils/Properties_es";
import FancyPaper from "../FancyPaper";
import { KeyIcon, PenIcon, PlusIcon, TrashIcon } from "../svg/icons/SvgIcons";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { getRequestRole } from "../../services/role/RoleService";
import DnsIcon from '@mui/icons-material/Dns';

const SettingUser = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    isSuperAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [usersData, setUsersData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userRole, setUserRole] = useState<any>([]);

  const handleData = async (
    currentPage: number,
    filter?: string,
  ) => {
    setLoading && setLoading(true);
    try {
      filter = filter?filter:'';
      setPreFilter(filter);
      setPage(currentPage);
     
      let userRoleData = await getRequestRole('');
      if (userRoleData) {
        setUserRole(userRoleData);
      } 

      let data = await getRequestUser(currentPage, filter);
      if (data) {
        setUsersData(data);
      }
      
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  useEffect(()=>{
    handleData(0);

  },[authInfo])

  const initForm = {
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: null,
    status: "ACTIVE",
    roleID: null,
    activationCode: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleData(0, preFilter);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {

console.log('handleAdd', data)

    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createUser({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingUser"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("successfullyCreatedUser"));
      //call fetch data
      await handleData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateUser({
        ...data,
        userModified: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(t("errorUpdatingUser"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("userUpdatedCorrectly"));
      //call fetch data
      await handleData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar password del usuario
   * @param data
   */
  const handlePassUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let updateData = await updatePassRequest(data);

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(t("errorUpdatingPassword"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("passwordUpdatedCorrectly"));

      //call fetch data
      await handleData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);

      if (!deleteData) {
        setErrorMsg &&
        setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
      setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      setPage(0);
      await handleData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");

    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "passchange") {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
    }



    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "passchange":
        await handlePassUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  const getStatusText = (statusNumber: string) => {
    if(statusNumber === 'ACTIVE')
      return <Tooltip title={t("active")}><CheckCircleIcon color="success"/></Tooltip>;
    else if(statusNumber === 'PENDING')
      return <Tooltip title={t("pending")}><ErrorIcon color="warning"/></Tooltip>;
    else if(statusNumber === 'DELETED')
      return <Tooltip title={t("deleted")}><CancelIcon color="error"/></Tooltip>;
    return t("unknown");
  };

  const getDomainText = (domains: any[]) => {
    let list = '';
    let colorText = '#66bb6a'
    domains.forEach((d: any)=>{
      list += `${d.url}\n`
    });
    if(list == ''){
      list = t('configDomain');
      colorText = '#f44336'
    }
    return <Tooltip title={<div style={{ whiteSpace: 'pre-line', color: colorText }}>{list}</div>}><DnsIcon color="primary"/></Tooltip>;
  };

  return (
    <>
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper pagetitle={`${t("setting")} / ${t("users")}`}>
        <Grid container spacing={2} sx={{ padding: 2}}>
          <Grid item md={9} sm={6} xs={12} >
            {/* <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}> */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                {t("addUser")}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            {/* </ResourceAccess> */}
          </Grid>
          {/* <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder={t("searchFilter")}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                {t("search")}
              </Button>
            </Stack>
          </Grid> */}
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("id")}</TableCell>
                <TableCell>{t("email")}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("lastName")}</TableCell>
                <TableCell align="center">{t("domain")}</TableCell>
                <TableCell align="center">{t("state")}</TableCell>
                <TableCell align="center">{t("profile")}</TableCell>
                <TableCell align="center">{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData &&
                usersData.content &&
                usersData.content
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, i: number) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {i+1}
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{getDomainText(row.authDomains)}</TableCell>
                      <TableCell align="center">{getStatusText(row.status)}</TableCell>
                      <TableCell align="center">{row.userRole}</TableCell>
                      <TableCell align="center">
                        {/* <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}> */}
                          <FancyTooltip title={t("edit")} placement="top">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              onClick={handleOpenModal}
                              data-name="update"
                              data-id={row.id}
                            >
                              <PenIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip
                            title={t("updatePassword")}
                            placement="top"
                          >
                            <IconButton
                              aria-label="sorter"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              data-id={row.id}
                              onClick={handleOpenModal}
                              data-name="passchange"
                            >
                              <KeyIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip title={t("delete")} placement="top">
                            <IconButton
                              aria-label="trash"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              data-id={row.id}
                              onClick={handleOpenModal}
                              data-name="delete"
                            >
                              <TrashIcon />
                            </IconButton>
                          </FancyTooltip>
                        {/* </ResourceAccess> */}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            usersData?.content?.length > 0 ? usersData?.content?.length : 0
          }
          rowsPerPage={usersData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={usersData.totalElements}
          totalPages={usersData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateUserModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            refreshData={handleData}
            modalType={modalData?.modalType}
            roleData={userRole?.content}
          />
        </Dialog>
      )}
      {modalData?.modalType === "passchange" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateUserPassModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                 {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingUser;
