import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";

import { AuthLayout } from '../components/security/AuthLayout';
import Login from '../pages/auth/Login';
import DashboardHome from "../layouts/DashboardHome";
import Home from "../pages/home/Home";
import { getAuthData } from "../utils/LocalStorageManager";
import CountingArea from "../pages/countingArea/CountingArea";
import Devices from "../pages/devices/Devices";
import RememberPassword from "../pages/auth/RememberPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ActiveRegister from "../pages/session/ActiveRegister";
import SettingUser from "../components/util/SettingUser";
import { lazy } from "react";
import Search from "../pages/search/Search";

const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Login />} />


      <Route path="/rememberpassword" element={<RememberPassword />} />
      <Route
        path="/forgot-password/:code/:email"
        element={<ForgotPassword />}
      />
      <Route
        path="/active-register/:code/:email"
        element={<ActiveRegister />}
      />


      <Route path="/dashboard" element={<DashboardHome />}>
        <Route path="home" element={<Home />} />
        {/* <Route path="devices" element={<Devices />} /> */}
        <Route path="search" element={<Search />} />
        <Route path="countingArea/:idCountingArea" element={<CountingArea />} />
        <Route path="settinguser" element={<SettingUser />} />
        
        
      </Route>
      <Route path="/notfound" element={<NotFound />} />
    </Route>
  )
)
