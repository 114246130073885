import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { valideAvailableUser } from "../../services/users/UserService";

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface DataUser {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  message: string;
}

interface DialogProps {
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const ContactUsModal = ({
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();
  const [availableEmail, setAvailableEmail] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    email: Yup.string()
      .required(t("fieldRequired"))
      .email(t("invalidEmail")),
    firstName: Yup.string().required(t("fieldRequired")),
    lastName: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    message: Yup.string().required(t("fieldRequired")),
  });
  const { setLoading, setErrorMsg, isSuperAdmin, setSuccessMsg,authInfo } = useApp();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<DataUser>({
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const validateEmail = async (email: string) => {
    // Expresión regular para validar el formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    try {
      // Verificar si el correo electrónico tiene el formato correcto
      if (!emailRegex.test(email)) {
        // El correo electrónico no tiene el formato correcto
        setAvailableEmail(false);
        return;
      }
  
      // El correo electrónico tiene el formato correcto, continuar con la validación
      const isAvailable: boolean = await valideAvailableUser(email);
      setAvailableEmail(isAvailable);
    } catch (error) {
      console.error("Error al validar el correo electrónico:", error);
    }
  };

   /**
   * Efecto inicial para carga de registros
   */
   useEffect(() => {
    const dataInit = async () => {
      
    };
    dataInit();
  }, [authInfo]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {t("contactUs")}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
            <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={6}>
                <Controller
                  name={"firstName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("name")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="firstName"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("firstName")}
                      error={errors.firstName && Boolean(errors.firstName)}
                      helperText={errors.firstName && errors.firstName.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"lastName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("lastName")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="lastName"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("lastName")}
                      error={errors.lastName && Boolean(errors.lastName)}
                      helperText={errors.lastName && errors.lastName.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={"phone"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("phone")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="phone"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("phone")}
                      error={errors.phone && Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"email"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      value={value}
                      autoComplete="email"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("email")}
                      onChange={(e) => {
                        setValue("email", e.target.value);
                        validateEmail(e.target.value);
                      }}
                      error={availableEmail || (errors.email && Boolean(errors.email))}
                      helperText={
                        (availableEmail && t("emailAlreadyExists")) ||
                        (errors.email && errors.email.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"message"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("message")}
                      type="area"
                      variant="standard"
                      value={value}
                      autoComplete="message"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("message")}
                      error={errors.message && Boolean(errors.message)}
                      helperText={errors.message && errors.message.message}
                    />
                  )}
                />
              </Grid>              
              
            </Grid>       
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            {t("send")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={cancelModal}
            autoFocus
          >
            {t("cancel")}
          </Button>{" "}
      </DialogActions>
    </>
  );
};

export default ContactUsModal;
