import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const CardDashboard = (props: any) => {
  const { sx, value, iconCard, title, subtitle, colorIcon, cursor } = props;
  return (
    <Card
      sx={{
        m: 1,
        height: "100%",
        background: "#212121",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        cursor: `${cursor}`
      }}
    >
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.primary" variant="h5">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: `${colorIcon}`,
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon sx={{ fontSize: "1.5rem" }}>{iconCard}</SvgIcon>
          </Avatar>
        </Stack>
        <Stack>
          <Typography color="info.dark" variant="caption">
            {subtitle}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardDashboard;

CardDashboard.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};
