
import { Facebook, Instagram } from "@mui/icons-material";
import {
  Grid,
  Link,
  Stack,
  SvgIcon,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Linkedin } from "../components/svg/icons/SvgIcons";



const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  display: "inline-block",
  marginBottom: "0.15rem",
}));

const HoverableIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "1.625rem",
  width: 26,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="row"
        sx={{ mt: "1.5rem", mb: "0.5rem" }}
      >
        <Link
          href="https://www.facebook.com/bitcodeenterprise/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <HoverableIcon>
            <Facebook />
          </HoverableIcon>
        </Link>
        <Link
          href="https://instagram.com/bitcodeenterprise"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <HoverableIcon>
            <Instagram />
          </HoverableIcon>
        </Link>

        <Link
          href="https://www.linkedin.com/company/bitcode-enterprise/mycompany/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <HoverableIcon>
            <Linkedin />
          </HoverableIcon>
        </Link>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <Typography>
          <LinkText href="mailto:info@mappriskdr.com">
            info@bitcode-enterprise.com
          </LinkText>
        </Typography>
        <Typography color="secondary.dark" variant="body1">
          © 2024 by Bitcode Enterprise
        </Typography>
      </Stack>
    </>
  );
};

export default Footer;

