import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";

export const searchHistoryV13 = async (data: any) => {
  let responseData = {};
  const requestBody = {
    cookie: data.cookie,
    url: data.urlDomail 
  };
  await privateFetch

    .post(URL.REQUEST_SEARCH_HISTORY_V13(),requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};

export const searchImage = async (data: any) => {
  let responseData = {};
  const requestBody = {
    cookie: data.cookie,
    url: data.url,
    source: data.source,
    deviceId: data.deviceId,
    ntpTime: data.ntpTime,
    objectId: data.objectId
  };

  await privateFetch

    .post(URL.REQUEST_SEARCH_IMAGE(),requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};
