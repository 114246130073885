import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";

export const getAllRequestDevices = async (data: any) => {
  
  let responseData = {};
  const requestBody = {
      cookie: data.cookie,
      url: data.urlDomail 
  };
  await privateFetch

    .post(URL.REQUEST_DEVICE_LIST_ALL,requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};

export const getByGuidRequestDevices = async (data: any, guid: string) => {
  let responseData = {};
  const requestBody = {
    cookie: data.cookie,
    url: data.urlDomail 
  };
  await privateFetch

    .post(URL.REQUEST_DEVICE_BY_GUID(guid),requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};

export const getAllRequestGroupsDevices = async (data: any) => {
  let responseData = {};
  const requestBody = {
      cookie: data.cookie,
      url: data.urlDomail 
  };
  await privateFetch

    .post(URL.REQUEST_DEVICE_GROUPS_LIST_ALL,requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};

export const getAllRequestSitesDevices = async (data: any) => {
  let responseData = {};
  const requestBody = {
      cookie: data.cookie,
      url: data.urlDomail 
  };
  await privateFetch

    .post(URL.REQUEST_DEVICE_SITES_LIST_ALL,requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response?.data?.code && error.response?.data?.code === '002'
        && error.response?.data?.message && error.response?.data?.message !== '')
        throw new Error(error.response.data.message);
      throw new Error(properties.ERROR_LOAD_RECORD);
    });
  return responseData;
};