import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Paper,
  IconButton,
  styled,
  InputAdornment,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { RecoveryPassword } from "../../types/RecoveryPassword";
import Footer from "../../utils/Footer";
import { SVChangePassRequest } from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";
import MessageManager from "../../utils/MessageManager";
import Loading from "../../utils/Loading";
import { CONSTANT } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { BitcodeLogoWhite } from "../../components/svg/icons/SvgIcons";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { code } = useParams();
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
    language,
    handleLangChange,
  } = useApp();

  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const validation = Yup.object().shape({
    password: Yup.string().required("Contraseña es requerido"),
    repeatPassword: Yup.string().required("Confirmar contraseña es requerido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<RecoveryPassword>({
    resolver: yupResolver(validation),
  });
  const watch_password = watch("password");
  const watch_repeatPassword = watch("repeatPassword");

  useEffect(() => {
    if (watch_repeatPassword && watch_repeatPassword !== watch_password) {
      setError("repeatPassword", {
        type: "custom",
        message: `${t("passMustSame")}`,
      });
    }
  }, [watch_repeatPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data: any) => {
    try {

      setLoading && setLoading(true);

      const resultData: any = await SVChangePassRequest({
        id: code,
        password: data.password,
      });

      setSuccessMsg && setSuccessMsg(resultData.info);
      setLoading && setLoading(false);
      navigate("/", { replace: true });
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? error.message
            : properties.com_motorola_label_login_general_error
        );
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}

      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: -5, py: 4 }}
      >
        <BitcodeLogoWhite />
        <Stack
          direction="row"
          justifyContent="center" // inicio de sesión y cambio de idioma a la derecha
          alignItems="center" // alinear verticalmente los elementos
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
            margin: "auto", // centrar el Stack horizontalmente
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              mt: `${theme.spacing(1)}!important`,
              mb: `${theme.spacing(1)}!important`,
            }}
          >
            {t("recoverPass")}
          </Typography>
          <Select
            value={language}
            autoWidth
            sx={{
              margin: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              "& .MuiSelect-select": {
                minWidth: {
                  xs: "30px!important",
                  md: "90px!important",
                },
              },
            }}
            onChange={(e: any) =>
              handleLangChange && handleLangChange(e.target.value)
            }
          >
            <MenuItem value="es">{isSm ? "ES" : t("spanish")}</MenuItem>
            <MenuItem value="en">{isSm ? "EN" : t("english")}</MenuItem>
          </Select>          
        </Stack>
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            // background: "#F8F5F3",
            marginBottom: `${theme.spacing(2)}!important`,
            padding: {
              xs: theme.spacing(6),
              md: `${theme.spacing(6)} ${theme.spacing(8)}`,
            },
          }}
        >
          <form>
            <Grid container spacing={4}>

              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("password")}
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      autoComplete="password"
                      {...register("password")}
                      error={errors.password && Boolean(errors.password)}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name={"repeatPassword"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("confirmPassword")}
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      autoComplete="repeatPassword"
                      {...register("repeatPassword")}
                      error={
                        errors.repeatPassword && Boolean(errors.repeatPassword)
                      }
                      helperText={
                        errors.repeatPassword && errors.repeatPassword.message
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  data-name="update"
                  name="update"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    borderRadius: "0.25rem",
                    mt: 3,
                  }}
                >
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="/" sx={{color: theme.palette.info.dark}}>{t("returnToLogin")}</LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default ForgotPassword;
