import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"

export const getRequestUser = async (page: any, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);  
    await privateFetch
    .get(
      URL.REQUEST_USER_LIST(page,UrlBase.registration_x_page),  
      {params}  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
     
    });
    return responseData;   
};

export const findByIdRequest = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_motorola_label_role_findid_error);
    });
  return responseData;
};

export const createUser = async (data: any) => {
  let responseData = {};
  const requestBody = {
    username: data.username,    
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: data.roleID?.id,
    status: data.status,
    password: data.password,    
    authDomains: data.authDomains,
    userCreated: data.userCreated
    
  };
  await privateFetch
    .post(URL.REQUEST_USER_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      //console.log(error)
    });
  return responseData;
};

export const updateUser = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    username: data.username,    
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.roleID?.id,
    password: data.password,
    userModified: data.userModified,
    authDomains: data.authDomains
  }; 
  await privateFetch
    .put(URL.REQUEST_USER(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_motorola_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updatePassRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    password: data.password
  }; 
  await privateFetch
    .put(URL.REQUEST_USER_PASS, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_motorola_label_user_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_USER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_motorola_label_user_delete_error
      );
    });
  return responseData;
};

export const activateUser = async (code: string, username: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ACTIVATE_USER(code, username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error activando usuario");
    });
  return responseData;
};

export const valideAvailableUser = async (username: string) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_VALIDATE_AVAILABLE_USER(username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    const isAvailable = response.data && response.data.exists === true;

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};

export const sendMessageContactUs = async (data: any) => {
  let responseData = {};
  try {
    const requestBody = {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      message: data?.message,
    };

    const response = await privateFetch.post(URL.REQUEST_CONTACT_US(), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    responseData = await response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
  return responseData;
};

export const valideAvailablePassword = async (userId: number, password: string) => {
  try {
    const requestBody = {
      newPassword: password.toString(),
    };

    const response = await privateFetch.post(URL.REQUEST_VALIDATE_AVAILABLE_PASSWORD(userId), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    const isAvailable = response.data && response.data.matches === true;

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};

export const countUserInofrmation = async () => {
  let responseData = {};
  try {
    const response = await privateFetch.get(URL.REQUEST_USER_COUNT(), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });
    responseData = response;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
  return responseData;
};