export const CONSTANT = {

  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX:"/security",
  DISPATCHER_USER_PREFIX:"/users",
  DISPATCHER_CORE_PREFIX: "/core",

  /** MOTOROLA PREFIX */
  DISPATCHER_AUTH_PREFIX:"/auth",
  DISPATCHER_DEVICE_PREFIX:"/device",
  DISPATCHER_COUNTINGAREA_PREFIX: "/countingAreas",
  DISPATCHER_MULTITIMELINEINFORMATION_PREFIX: "/multiTimelineInformation",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

  /** DEPARTMENT */
  DEPARTMENT1_VALUE:"Contabilidad",
  DEPARTMENT2_VALUE:"Operaciones",
  DEPARTMENT3_VALUE:"Servicio al cliente",

  /** PORTAL */
  PORTAL_DD:"1",
  PORTAL_GL:"2",
  PORTAL_OP:"3",
  
   /** TAX */
   TAX_VALUE:0.18,

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** PHONETYPE */
  CODPHONE: 506,

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  },

  HEADER_TYPE_DOCUMENTS: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data;",
  },

  /** REQUEST STATUS INFO */
  STATUS_INFO_ACTIVE: "ACTIVE",
  STATUS_INFO_DELETE: "DELETED",
  STATUS_INFO_UPDATED: "UPDATED",

  /** USER ROLES */
  USER_ADMINISTRATOR_ROLE: "Administrador",
  USER_EDITOR_ROLE: "Editor",
  USER_READER_ROLE: "Lector",

  DATE_FORMAT: "dd/MM/yyyy",

};
