import { FC} from "react";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Paper,
  styled,
  Select,
  MenuItem,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { rememberPasword } from "../../types/RememberPassword";
import Footer from "../../utils/Footer";
import { SVRecoveryPassRequest } from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";
import MessageManager from "../../utils/MessageManager";
import Loading from "../../utils/Loading";
import { CONSTANT } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { BitcodeLogoWhite } from "../../components/svg/icons/SvgIcons";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const RememberPassword: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
    language,
    handleLangChange,
  } = useApp();

  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const validation = Yup.object().shape({
    username: Yup.string()
      .required(t("emailRequired"))
      .email(t("emailInvalid")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<rememberPasword>({
    resolver: yupResolver(validation),
  });

  const revoveryData: any = {
    // JSON data here
  };
  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);

      //Authenticate user
      const revoveryData: any = await SVRecoveryPassRequest(data);
      setSuccessMsg && setSuccessMsg(t("recoveryEmailSend"));

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? t("errorSendingEmail")
            : properties.com_motorola_label_login_general_error
        );
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}

      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: -5, py: 4 }}
      >
        <Box>
          <BitcodeLogoWhite />
        </Box>
        <Stack
          direction="row"
          justifyContent="center" // inicio de sesión y cambio de idioma a la derecha
          alignItems="center" // alinear verticalmente los elementos
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
            margin: "auto", // centrar el Stack horizontalmente
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              mt: `${theme.spacing(1)}!important`,
              mb: `${theme.spacing(1)}!important`,
            }}
          >
            {t("recoverPass")}
          </Typography>
          <Select
            value={language}
            autoWidth
            sx={{
              margin: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              "& .MuiSelect-select": {
                minWidth: {
                  xs: "30px!important",
                  md: "90px!important",
                },
              },
            }}
            onChange={(e: any) =>
              handleLangChange && handleLangChange(e.target.value)
            }
          >
            <MenuItem value="es">{isSm ? "ES" : t("spanish")}</MenuItem>
            <MenuItem value="en">{isSm ? "EN" : t("english")}</MenuItem>
          </Select>
        </Stack>
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            // background: "#F8F5F3",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(6),
              md: `${theme.spacing(6)} ${theme.spacing(8)}`,
            },
          }}
        >
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  name={"username"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      autoComplete="username"
                      {...register("username")}
                      error={errors.username && Boolean(errors.username)}
                      helperText={errors.username && errors.username.message}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  data-name="update"
                  name="update"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    borderRadius: "0.25rem",
                    mt: 3,
                  }}
                >
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="/" sx={{color: theme.palette.info.dark}}>{t("returnToLogin")}</LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default RememberPassword;
