
import { CONSTANT } from "../../utils/Constants";
import { publicFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";

export const SVloginRequest = async (data: { username: string; url: string; id: number; }) => {
    let responseData = {};
    const requestBody = {
        username: data.username,
        url: data.url,
        id: data.id
    };
    await publicFetch
        .post(URL.CORE_LOGIN_REQUEST, requestBody, {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            if (
                error.response.data.status === 401 ||
                error.response.data.status === 500
            ) {
                throw new Error(error.response.data.status);
            } else {
                throw new Error(error.response.data.message);
            }
        });

    return responseData;
};

export const SVlogoutRequest = async (domaingObj: any) => {
    let responseData = {};
    const requestBody = {
        cookie: domaingObj.cookie,
        url:domaingObj.urlDomail
    };
    await publicFetch
        .post(URL.CORE_LOGOUT_REQUEST, requestBody, {
            headers: {...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: { response: { status: number; }; }) => {
            if (
                error.response &&
                (error.response.status === 401 || error.response.status === 500)
            ) {
                throw new Error(
                    "El código digitado es inválido o ha expirado, por favor solicite un nuevo código"
                );
            }
            throw new Error(
                "Ha ocurrido un error inesperado, por favor intente más tarde"
            );
        });

    return responseData;
};
