import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { activateUser, valideAvailableUser } from "../../services/users/UserService";
import { properties } from "../../utils/Properties_es";
import { formSchemaOptionsName } from "../../types/Utils";
import { PlusIcon, TrashIcon } from "../svg/icons/SvgIcons";
import theme from "../../theme/theme";
import FancyTooltip from "./FancyTooltip";

interface AddOrUpdateUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  status: string;
  authDomains: any[];
  roleID: {
    id?: number;
    name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  refreshData: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  refreshData,
  modalType,
  roleData,
}: DialogProps) => {
  const { t } = useTranslation();
  const [availableEmail, setAvailableEmail] = useState(false);
  const [showDomains, setShowDomains] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    username: Yup.string()
      .required(t("fieldRequired"))
      .email(t("invalidEmail")),
    firstName: Yup.string().required(t("fieldRequired")),
    lastName: Yup.string().required(t("fieldRequired")),
    password: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    status: Yup.string(),
    authDomains: Yup.array(
      Yup.object({
        id: Yup.number(),
        url: Yup.string().required(t("fieldRequired")),
        username: Yup.string().required(t("fieldRequired")),
        password: Yup.string().required(t("fieldRequired")),
      })
    ),
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError(t("fieldRequired")),
  });
  const { setLoading, setErrorMsg, isSuperAdmin, setSuccessMsg,authInfo } = useApp();

  const defaultValues = {
    id: data ? data.id : 0,
    username: data ? data.username : "",
    firstName: data ? data.firstName : "",
    lastName: data ? data.lastName : "",
    password: data ? data.password : "",
    phone: data ? data.phone : "",
    status: data ? data.status : "",
    roleID: data ? roleData.find((f: any) => f.roleName === data.userRole) : [],
    activationCode: data ? data.activationCode : "",
    authDomains: data ? data.authDomains : []
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    // resolver: yupResolver(validation),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "authDomains",
  });

  const handleActiveUser = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        data.activationCode,
        data.username
      );
      if (!dataActiveUser) {
        setErrorMsg && setErrorMsg(properties.com_motorola_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }

      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("userHasBeenActivated"));
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
    refreshData(0, "");
    cancelModal();
  };

  const validateEmail = async (email: string) => {
    // Expresión regular para validar el formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    try {
      // Verificar si el correo electrónico tiene el formato correcto
      if (!emailRegex.test(email)) {
        // El correo electrónico no tiene el formato correcto
        setAvailableEmail(false);
        return;
      }
  
      // El correo electrónico tiene el formato correcto, continuar con la validación
      const isAvailable: boolean = await valideAvailableUser(email);
      setAvailableEmail(isAvailable);
    } catch (error) {
      console.error("Error al validar el correo electrónico:", error);
    }
  };

  const handleAddDomain = async () => {
    setShowDomains(!!!showDomains);
  };

   /**
   * Efecto inicial para carga de registros
   */
   useEffect(() => {
    const dataInit = async () => {
      console.log('authInfo',authInfo)
    };
    dataInit();
  }, [authInfo]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? !showDomains ? t("updateUser") : t("domains")
          : !showDomains ? t("addUser") : t("domains")}
        {data && data.status === 'DELETED' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActiveUser(data)}
          >
            {t("activateUser")}
          </Button>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          { !showDomains && (
            <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={12}>
                <Controller
                  name={"firstName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("name")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="firstName"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("firstName")}
                      error={errors.firstName && Boolean(errors.firstName)}
                      helperText={errors.firstName && errors.firstName.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"lastName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("lastName")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="lastName"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("lastName")}
                      error={errors.lastName && Boolean(errors.lastName)}
                      helperText={errors.lastName && errors.lastName.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name={"phone"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("phone")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="phone"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("phone")}
                      error={errors.phone && Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"username"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      value={value}
                      autoComplete="username3"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("username")}
                      onChange={(e) => {
                        setValue("username", e.target.value);
                        validateEmail(e.target.value);
                      }}
                      error={availableEmail || (errors.username && Boolean(errors.username))}
                      helperText={
                        (availableEmail && t("emailAlreadyExists")) ||
                        (errors.username && errors.username.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="roleID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={roleData}
                      getOptionLabel={(option: any) => option.roleName || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("profile")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.roleID}
                            helperText={errors.roleID && errors.roleID.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              
              {modalType === "create" && (
                <Grid item xs={12}>
                  <Controller
                    name={"password"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={t("password")}
                        type="password"
                        variant="standard"
                        value={value}
                        autoComplete="password"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("password")}
                        error={!!errors.password}
                        helperText={errors.password && errors.password.message}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}
          { showDomains && (

            <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
              <Grid item xs={12}>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={() => append({ id: null,url: '',userCreated: authInfo.username ,userModified: null,username : '',password : '' ,userId: data?.id ? data?.id : 0 })}
                  >
                  {t("addDomain")}
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>

                  {fields.map((form, index) => {
                    return (
                      <div key={index}>
                        <Grid item xs={12}>
                        <TextField
                          // name='url'
                          label={t("domain")}
                          variant="standard"
                          // onChange={event => handleFormChange(event, index)}
                          // value={form.url}
                          sx={{ m: 1, width: '80%' }} 
                          {...register(`authDomains.${index}.url` as const)}
                        />
                        <FancyTooltip title={t("delete")} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={form.id}
                            onClick={() => remove(index)}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                          // name='username'
                          label={t("username")}
                          variant="standard"
                          // onChange={event => handleFormChange(event, index)}
                          // value={form.username}
                          sx={{ m: 1, width: '40%' }} 
                          {...register(`authDomains.${index}.username` as const)}
                        />
                        <TextField
                          label={t("password")}
                          variant="standard"
                          // onChange={event => handleFormChange(event, index)}
                          // value={form.password}
                          sx={{ m: 1, width: '40%' }} 
                          {...register(`authDomains.${index}.password` as const)}
                        />
                        </Grid>
                        <Divider sx={{borderBottomWidth: 5}} />
                      </div>
                    )
                  })}

              </Grid>

            </Grid>
          )}
        </form>
      </DialogContent>
      { !showDomains && (
        <DialogActions sx={{ pb: 4, px: 4 }}>

          {/* { data?.id && ( */}
              <Button
                variant="contained"
                color="success"
                sx={{
                  mt: 2,
                  mr: 2,
                }}
                onClick={handleAddDomain}
              >
                {t("addDomain")}
              </Button>
          {/* )} */}
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={cancelModal}
            autoFocus
          >
            {t("cancel")}
          </Button>{" "}
        </DialogActions>
      )}
      { showDomains && (
        <DialogActions sx={{ pb: 4, px: 4 }}>
          <Button
            variant="contained"
            color="success"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleAddDomain}
          >
            {t("back")}
          </Button>{" "}
        </DialogActions>
      )}
    </>
  );
};

export default AddOrUpdateUserModal;
