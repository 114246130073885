import React, { useEffect, useState } from "react";
import FancyPaper from "../../components/FancyPaper";
import { Box, Button, Card, CardContent, Dialog, Grid, Stack, Typography } from "@mui/material";
import CardDashboard from "../../components/util/CardDashboard";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/useApp";
import ContactUsModal from "../../components/util/ContactUsModal";
import { countUserInofrmation, sendMessageContactUs } from "../../services/users/UserService";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CropIcon from '@mui/icons-material/Crop';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-charts-community";

const Home = () => {
    const { t } = useTranslation();
    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authMotorolaInfo,
        totalCountingArea,
        totalDevice,
        modalData,
        setModalData,
        isSuperAdmin,
        deviceTree,
        devices,
    } = useApp();
    
    const [totalUser, setTotalUser] = useState<number>(0);
    const [totalUserActive, setTotalUserActive] = useState<number>(0);
    const [optionsAgChartsBar, setOptionsAgChartsBar] = useState<AgChartOptions>({theme: "ag-default-dark", data:[]});
    const [optionsAgChartsPie, setOptionsAgChartsPie] = useState<AgChartOptions>({theme: "ag-default-dark", data:[]});

    useEffect(()=>{
        const init = async ()=> {
            if(isSuperAdmin)
                countUser();
        }

        init();
    },[isSuperAdmin]);

    const countUser = async () => {
        setLoading && setLoading(true);
        try {
            let response: any = await countUserInofrmation();
            if (response?.status == 200) {
              
                setTotalUser(response?.data?.totalUser);
                setTotalUserActive(response?.data?.totalUserActive);
            }
            setLoading && setLoading(false);
        
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
      };

    const onSubmit = async (data: any) => {
        try {
          setLoading && setLoading(true);

          const response: any = await sendMessageContactUs(data);
          
          setSuccessMsg && setSuccessMsg(t("messageSent"));    
          setLoading && setLoading(false);
          handleCancelModal();
        } catch (error: any) {
          setLoading && setLoading(false);
          setErrorMsg &&
            setErrorMsg(t("errorSendingEmail"));
        }
    };

    /**
     * Evento de apertura de modal
     */
    const handleOpenModal = async (event: any) => {
        event.preventDefault();

        //open modal
        setModalData &&
        setModalData({
            ...modalData,
            modalOpen: true,
            modalType: 'contactus',
            modalObject: null,
        });
    };

    /**
     * Evento de cierre de modal
     * @param event
     */
    const handleCancelModal = () => {
        setModalData &&
        setModalData({
            ...modalData,
            modalOpen: false,
            modalType: "",
            modalObject: null,
        });
    };


    useEffect(()=>{
        const init = () => {
            if(devices?.length > 0){

                let january: number = 0;
                let february: number = 0;
                let march: number = 0;
                let april: number = 0;
                let may: number = 0;
                let june: number = 0;
                let july: number = 0;
                let august: number = 0;
                let september: number = 0;
                let october: number = 0;
                let november: number = 0;
                let december: number = 0;

                const currentYear: number = new Date().getFullYear();
                const currentMonth: number = new Date().getMonth();
                
                devices.forEach((element: any) => {
                    const dateTimeString: string = element.created_at;
                    const [datePart] = dateTimeString.split('T');
                    const [year, month, day] = datePart.split('-').map(part => parseInt(part, 10));
                    const date: Date = new Date(year, month - 1, day, 0, 0, 0);

                    const dateJanuary: Date = new Date(currentYear, 1, 1, 0, 0, 0);
                    if(dateJanuary > date && currentMonth >= dateJanuary.getMonth()-1)
                        january++;

                    const dateFebruary: Date = new Date(currentYear, 2, 1, 0, 0, 0);
                    if(dateFebruary > date && currentMonth >= dateFebruary.getMonth()-1)
                        february++;

                    const dateMarch: Date = new Date(currentYear, 3, 1, 0, 0, 0);
                    if(dateMarch > date && currentMonth >= dateMarch.getMonth()-1)
                        march++;

                    const dateApril: Date = new Date(currentYear, 4, 1, 0, 0, 0);
                    if(dateApril > date && currentMonth >= dateApril.getMonth()-1)
                        april++;

                    const dateMay: Date = new Date(currentYear, 5, 1, 0, 0, 0);
                    if(dateMay > date && currentMonth >= dateMay.getMonth()-1)
                        may++;

                    const dateJune: Date = new Date(currentYear, 6, 1, 0, 0, 0);
                    if(dateJune > date && currentMonth >= dateJune.getMonth()-1)
                        june++;

                    const dateJuly: Date = new Date(currentYear, 7, 1, 0, 0, 0);
                    if(dateJuly > date && currentMonth >= dateJuly.getMonth()-1)
                        july++;

                    const dateAugust: Date = new Date(currentYear, 8, 1, 0, 0, 0);
                    if(dateAugust > date && currentMonth >= dateAugust.getMonth()-1)
                        august++;

                    const dateSeptember: Date = new Date(currentYear, 9, 1, 0, 0, 0);
                    if(dateSeptember > date && currentMonth >= dateSeptember.getMonth()-1)
                        september++;

                    const dateOctober: Date = new Date(currentYear, 10, 1, 0, 0, 0);
                    if(dateOctober > date && currentMonth >= dateOctober.getMonth()-1)
                        october++;

                    const dateNovember: Date = new Date(currentYear, 11, 1, 0, 0, 0);
                    if(dateNovember > date && currentMonth >= dateNovember.getMonth()-1)
                        november++;

                    const dateDecember: Date = new Date(currentYear, 11, 31, 23, 59, 59);
                    if(dateDecember >= date && currentMonth >= dateDecember.getMonth())
                        december++;
                });


                let data : any[] = [
                    {monthName: t('january'), count: january},
                    {monthName: t('february'), count: february},
                    {monthName: t('march'), count: march},
                    {monthName: t('april'), count: april},
                    {monthName: t('may'), count: may},
                    {monthName: t('june'), count: june},
                    {monthName: t('july'), count: july},
                    {monthName: t('august'), count: august},
                    {monthName: t('september'), count: september},
                    {monthName: t('october'), count: october},
                    {monthName: t('november'), count: november},
                    {monthName: t('december'), count: december},
                ];

                setOptionsAgChartsBar({
                    theme: "ag-default-dark",
                    title: {
                        text: t("numberDevicesPerMonth"),
                    },
                    data: data,
                    series: [{
                        type: "bar",
                        xKey: "monthName",
                        yKey: "count",
                        yName: t('months'),
                    }]
                });
            }
        }
        init();
    },[devices]);

    useEffect(()=>{
        const init = async ()=> {
            let agChartsSeriesPie : any[] = [];

            agChartsSeriesPie = [
                {
                    type: "pie",
                    angleKey: "countDevices",
                    legendItemKey: "name"
                }
            ];

            setOptionsAgChartsPie({
                theme: "ag-default-dark",
                title: {
                    text: t("numberDevicesPerSite"),
                },
                data: deviceTree,
                series: agChartsSeriesPie
            });
        }        
        init();
    },[deviceTree]);

    return (
        <>
            <Grid container spacing={4} mb={2} p={2}>
                <Grid item xs={6} md={3} >
                    <CardDashboard
                        m={1}
                        positive
                        value={
                            totalUser ? totalUser : 0
                        }
                        subtitle={`${t("users")} ${totalUser}/${totalUserActive}`}
                        title=""
                        colorIcon="#0288d1"
                        iconCard={<ManageAccountsIcon />}
                    />
                </Grid>
                <Grid item xs={6} md={3} >
                    <CardDashboard
                        m={1}
                        positive
                        value={ totalDevice ? totalDevice : 0 }
                        subtitle={t("totalDevices")}
                        title=""
                        colorIcon="#0288d1"
                        iconCard={<VideoCameraFrontIcon />}
                    />
                </Grid>
                <Grid item xs={6} md={3} >
                    <CardDashboard
                        m={1}
                        positive
                        value={
                        totalCountingArea ? totalCountingArea : 0
                        }
                        subtitle={t("totalCountingAreas")}
                        title=""
                        colorIcon="#0288d1"
                        iconCard={<CropIcon />}
                    />
                </Grid>
                <Grid item 
                    xs={6} 
                    md={3} 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    onClick={handleOpenModal}
                >
                    <Card
                        sx={{
                            m: 1,
                            height: "100%",
                            background: "#0288d1",
                            boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                            cursor: "pointer",
                            alignItems: "center",
                            display:  "flex",
                            justifyContent:"center",
                            flexDirection: "column"
                        }}
                    >
                        <CardContent>
                            <Typography color="white" variant="h4">
                                {t("contactUs")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {modalData?.modalType === "contactus" && (
                    <Dialog
                    open={modalData?.modalOpen ? modalData.modalOpen : false}
                    onClose={handleCancelModal}
                    fullWidth
                    >
                    <ContactUsModal
                        onSubmit={onSubmit}
                        cancelModal={handleCancelModal}
                        modalType={modalData?.modalType}
                    />
                    </Dialog>
                )}
            </Grid> 
             <Grid container sx={{p:1}} >
                { optionsAgChartsBar?.data?.length != 0 && (
                    <Grid xs={12} md={6} m={2}>
                        <AgCharts options={optionsAgChartsBar} />
                    </Grid>
                )}
                { optionsAgChartsPie?.data?.length != 0 && (                
                    <Grid xs={12} md={5} m={2}>
                        <AgCharts options={optionsAgChartsPie} />
                    </Grid>
                )}
            </Grid>       
        </>
    )
}

export default Home;
