import React, { useCallback, useEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import { 
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    MenuItem,
    Modal,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { searchHistoryV13, searchImage } from "../../services/search/SearchService";
import FancyPaper from "../../components/FancyPaper";
import dayjs from "dayjs";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-charts-community";
import { UrlBase } from "../../url/Urls";
import InfoIcon from '@mui/icons-material/Info';
import { CropLandscapeOutlined } from "@mui/icons-material";



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const Search = () => {
    const { t } = useTranslation();
    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authMotorolaInfo,
        devices
    } = useApp();

    const [searchHistoryAll, setSearchHistoryAll] = useState<any[]>([]);
    const [history, setHistory] = useState<any[]>([]);
    const [firstLoad, setFirstLoad] = useState<number>(0);  

    const [classCategories, setClassCategories] = useState<string>('');
    const [endTimeRange, setEndTimeRange] = useState<string>('');
    const [startTimeRange, setStartTimeRange] = useState<string>('');
    const [deviceSource, setDeviceSource] = useState<string>('');
    const [totalPeople, setTotalPeople] = useState<number>(0);

    const [receivedMessage, setReceivedMessage] = useState<any[]>([]);
    const [arrayData, setArrayData] = useState<any[]>([]);
    const [socket, setSocket] = useState<WebSocket | null>(null);

    const [selectSearch, setSelectSearch] = useState<any>({});

    const [stateChart, setStateChart] = React.useState({
        chartPie: false,
        chartLine: false,
        chartBar: false
    });

    const { chartPie, chartLine, chartBar} = stateChart; 
    const [chartType, setChartType] = useState<string>('bar');

    const [optionsAgChartsLine, setOptionsAgChartsLine] = useState<AgChartOptions>({});
    const [optionsAgChartsBar, setOptionsAgChartsBar] = useState<AgChartOptions>({});
    const [optionsAgChartsPie, setOptionsAgChartsPie] = useState<AgChartOptions>({});
    
    const [informationObject, setInformationObject] = useState<any | null>(null);
    const [loadingWebsocket, setLoadingWebsocket] = useState<boolean>(false);

    useEffect(() =>{
        if(authMotorolaInfo && firstLoad==0){
            setFirstLoad(1);
            loadAllHistory();
        }
    },[authMotorolaInfo]);

    const connectWebSocket = (message: string) => {

        const webSocket = new WebSocket(UrlBase.nodejs_websocket_base_url);
    
        webSocket.onopen = () => {
            console.log('Connected to the server');
            setLoadingWebsocket(true);
            setSocket(webSocket);
            webSocket.send(message);
        };
    
        webSocket.onmessage = (event) => {
            receivedMessageWebSocket(event);         
        };
    
        webSocket.onclose = () => {
            setSocket(null);
            console.log('Disconnected from the server');
        };
    
        webSocket.onerror = (error) => {
          console.error('WebSocket error:', error);
          setSocket(null);
          setErrorMsg && setErrorMsg('WebSocket error');
          setLoading && setLoading(false);
        };
    
        return () => {
            webSocket.close();
        };
    }
    
    /**
    * Metodo encargado de leer la respuesta del websocket
    * @param data
    */
    const receivedMessageWebSocket = async (event: any) => {
        try{
            const json = JSON.parse(JSON.parse(event.data));
            if(json && json?.action && json?.action === 'search_result' && json?.results && json?.results?.length > 0 ){
                json.results.forEach((item: any) => {
                    item.image = null;
                    setReceivedMessage(array => [...array,item]);
                });
            }  
            if(json && json?.action && json?.action === 'end'){
                setLoadingWebsocket(false);
            }
            
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    }

    
    /**
    * Metodo encargado de cargar las historias
    * @param data
    */
    const loadImage = async (source: string, 
            deviceId: string, 
            ntpTime: string,
            objectId: string
    ): Promise<string> => {
        let image: string = '';
        setLoading && setLoading(true);
        try {
            const dataRequest = {
                cookie: authMotorolaInfo.cookie,
                url: authMotorolaInfo.urlDomail,
                source: source,
                deviceId: deviceId,
                ntpTime: ntpTime,
                objectId: objectId,
            }
            let response: any = await searchImage(dataRequest);

            image = response;
            setLoading && setLoading(false);
        
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
        return image;
    };

    
    /**
    * Metodo encargado de cargar las historias
    * @param data
    */
    const loadAllHistory = async () => {
        setLoading && setLoading(true);
        try {
            let responseHistory: any = await searchHistoryV13(authMotorolaInfo);

            if (!responseHistory || responseHistory?.status !== 200) {
                setErrorMsg && setErrorMsg(t("noDataFoundDisplay"));
                setLoading && setLoading(false);
                return;
            }
            
            const data = responseHistory?.data;

            const keys = Object.keys(data?.value);
            let arrayHistory: any[]  = [];
            keys.forEach((key: string) => {
                arrayHistory.push(data?.value[key]);
            });
            setHistory(arrayHistory);

            setSearchHistoryAll(data);
            setLoading && setLoading(false);
        
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };
    
    const handleHistory = (value: any) => {
        setSelectSearch(value);

        if(value?.definition?.search_params?.appearance_search?.class_categories?.length > 0 )
            setClassCategories(t(value.definition.search_params.appearance_search.class_categories[0]));
        else
            setClassCategories('');

        if(value?.definition?.where?.device_sources?.length > 0 ){
            let devicesString = '';
            value.definition.where.device_sources.forEach((device: any)=>{

                const deviceFind = devices.find((d: any) => d.guid === device.device_id);
                if (deviceFind?.name)
                    devicesString += deviceFind.name+'\n';
            });            
            setDeviceSource(devicesString);
        }else
            setDeviceSource('');

        if(value?.definition?.time_range?.start_time)
            setStartTimeRange(dayjs(value?.definition?.time_range?.start_time).format('DD/MM/YYYY HH:mm:ss'));
        else
            setStartTimeRange('');

        if(value?.definition?.time_range?.end_time )
            setEndTimeRange(dayjs(value?.definition?.time_range?.end_time).format('DD/MM/YYYY HH:mm:ss'));
        else
            setEndTimeRange('');

        setReceivedMessage([]);
        setTotalPeople(0);
        setArrayData([]);
    };
    
    const handleWebSocketSendMessage = () => {

        setLoading && setLoading(true);
        let domain = `${authMotorolaInfo?.urlDomail}/api/v1/metadataSearch`;
        domain = domain.replace('http','ws');        
        const message = {
            wss: domain,
            cookie: authMotorolaInfo?.cookie,
            message: selectSearch?.definition
        };

        connectWebSocket(JSON.stringify(message));

    };
    
    const getDevice = (item: any) => {
        try{
            let firstOrLast = '';
            if(arrayData.length > 1 && item?.objects[0]){
                const firstItem = arrayData[0];
                const lastItem = arrayData[arrayData.length-1];
                
                if(item?.objects[0]?.id === firstItem?.objects[0]?.id && 
                    item?.objects[0]?.firstDetectedTime === firstItem?.objects[0]?.firstDetectedTime &&
                    item?.objects[0]?.lastDetectedTime === firstItem?.objects[0]?.lastDetectedTime
                )
                    firstOrLast=`<span style="color: orange;">${t('firstItem')}</span><br/>`;

                if(item?.objects[0]?.id === lastItem?.objects[0]?.id && 
                    item?.objects[0]?.firstDetectedTime === lastItem?.objects[0]?.firstDetectedTime &&
                    item?.objects[0]?.lastDetectedTime === lastItem?.objects[0]?.lastDetectedTime
                )
                    firstOrLast=`<span style="color: orange;">${t('lastItem')}</span><br/>`;
            }

            const deviceId = item?.deviceId;
            const deviceFind = devices.find((d: any) => d.guid === deviceId);
            return (
                <div dangerouslySetInnerHTML={{ __html: firstOrLast + (deviceFind?.name? deviceFind?.name : '') }} />
            );
        }catch(e){
            return '';
        }
    };
    
    const getDateTimeFormat = (date: number) => {
        try{
            return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
        }catch(e){
            return '';
        }
    };

    /**
    * Metodo encargado de 
    * @param data
    */
    const selectObject= async (item: any) => {
        const image = await loadImage(item?.source, item?.deviceId, item?.objects[0]?.matchTime, item?.objects[0]?.id);
        item.image = image;
        setInformationObject(item);
        handleOpen();
    }


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const init = () => {
            if(!loadingWebsocket){
                if(receivedMessage && receivedMessage?.length > 0){

                    const array: any[] = receivedMessage.filter((p2, index, self) =>
                        index === self.findIndex((p) => p.objects[0].id === p2.objects[0].id)
                    );

                    array.sort((a, b) => (a?.objects[0]?.firstDetectedTime < b?.objects[0]?.firstDetectedTime ? -1 : 1));
                    
                    const jsonHours : any[] = generateTimePoints(array[0]?.objects[0]?.firstDetectedTime, array[array.length-1]?.objects[0]?.lastDetectedTime);

                    let arrayChart: any[] = [];
                    jsonHours.forEach(element => {
                        let count = 0;
                        array.forEach(item => {
                            if(element.timestamp <= item?.objects[0]?.firstDetectedTime && (element.timestamp+3600000) > item?.objects[0]?.firstDetectedTime ){
                                count++;
                            }
                        });
                        arrayChart.push({
                            dateReference: dayjs(element.timestamp).format('DD/MM HH:mm'),
                            count: count
                        })
                    });

                    setOptionsAgChartsBar({
                        theme: "ag-default-dark",
                        title: {
                            text: t("numberPeoplePerHour"),
                        },
                        data: arrayChart,
                        series: [{
                            type: "bar",
                            xKey: "dateReference",
                            yKey: "count",
                            yName: t("numberPeoplePerHour"),
                        }],
                    });

                    setArrayData(array);
                    setLoading && setLoading(false);

                    if(socket){
                        socket.close();
                        setSocket(null);
                    }
                }else{
                    setLoading && setLoading(false);
                }
            }
        };
        init();
    },[loadingWebsocket]);

    useEffect(()=>{
        const init = () => { 
            setTotalPeople(arrayData.length);
        };
        init();
    },[arrayData]);

    const millisecondsToMinutes = (ms: number): string => {

        const msInHour = 3600000;
        const msInMinute = 60000;
        const msInSecond = 1000;

        const hours = Math.floor(ms / msInHour);
        const remainingMinutes = Math.floor((ms % msInHour) / msInMinute);
        const remainingSeconds = Math.floor((ms % msInMinute) / msInSecond);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(remainingMinutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        if(ms>=3600000)
            return `${formattedHours}:${formattedMinutes}`;
        else if(ms < 3600000 && ms >= 60000)
            return `${formattedMinutes}:${formattedSeconds}`;
        else
            return `${formattedSeconds}`;
    }


    interface TimePoint {
        timestamp: number; 
    }
      
    const millisecondsExactTime = (milisegundos: number): number => {
        const msPorHora = 3600000;
        const msPorMinuto = 60000;
        const msPorSegundo = 1000;
    
        // Calculate hours, minutes and seconds
        let horas = Math.floor(milisegundos / msPorHora);
        let milisegundosRestantes = milisegundos % msPorHora;
    
        const minutos = Math.floor(milisegundosRestantes / msPorMinuto);
        milisegundosRestantes %= msPorMinuto;
    
        const segundos = Math.floor(milisegundosRestantes / msPorSegundo);
    
        return horas * msPorHora; // We convert the adjusted hours back to milliseconds
    }

    /**
     * Generate a JSON array of time points between a start and end date.
     * @param startMillis - The start date in milliseconds.
     * @param endMillis - The end date in milliseconds.
     * @returns An array of objects, each representing a time point with a one-hour difference.
     */
    const generateTimePoints = (startMillis: number, endMillis: number): TimePoint[] => {
        const timePoints: TimePoint[] = [];
        
        // Validate the input dates
        if (startMillis >= endMillis) {
          throw new Error('Start date must be before end date.');
        }
    
        // Set the initial time to the start date
        let currentMillis = millisecondsExactTime(startMillis);;
      console.log('start',getDateTimeFormat(currentMillis))
      console.log('last',getDateTimeFormat(endMillis))
        // Generate time points until we reach the end date
        while (currentMillis <= endMillis) {
          // Create a new time point object
          const timePoint: TimePoint = {
            timestamp: currentMillis
          };
          console.log('currentMillis',getDateTimeFormat(currentMillis))
          // Add to the array
          timePoints.push(timePoint);
      
          // Increment by one hour (3600000 milliseconds)
          currentMillis += 3600000;
        }
      
        return timePoints;
    }
    
    return (
        <>
        <FancyPaper pagetitle={`${t("search")}`}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <Grid container direction="column" rowSpacing={1} >
                        <Grid   
                            container 
                            justifyContent="space-around"
                            alignItems="center" 
                            rowSpacing={1} 
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <TextField
                                id="outlined-select-search-history"
                                select
                                style={{width: 250}}
                                defaultValue={selectSearch}
                                value={selectSearch}
                                label={t("search")}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleHistory(event.target.value);
                                }}
                            >                    
                                {history.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                    {`${t(option.definition.search_params.appearance_search.class_categories[0])} - ${option.id}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        { selectSearch && selectSearch.definition && (
                            <Grid   
                                container 
                                justifyContent="space-around"
                                alignItems="center" 
                                rowSpacing={1} 
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ p: 3}}
                            >
                                <Grid item xs={6}>
                                    {t("what")}: {classCategories}
                                </Grid>
                                <Grid item xs={6}>
                                    {t("where")}: {deviceSource}
                                </Grid>
                                <Grid item xs={6}>
                                    {t("from")}: {startTimeRange}
                                </Grid>
                                <Grid item xs={6}>
                                    {t("to")}: {endTimeRange}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ margin: 2}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                            mt: 2,
                                            mr: 2,
                                            }}
                                            onClick={() => handleWebSocketSendMessage()}
                                        >
                                            {t("search")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <div>
                    <Grid container >                                                 
                        {chartType === 'bar' && arrayData?.length>0 && (  
                            <Box sx={{ width: '100%', margin: 2}}>                  
                                <Grid container xs={12} 
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    {t("objects")}: {totalPeople}
                                </Grid> 
                                <br/>
                                <AgCharts options={optionsAgChartsBar} />
                            </Box>
                        )} 
                        { arrayData?.length>0 && ( 
                            <Box sx={{ width: '100%', margin: 2}}>            
                                <Grid container xs={12} 
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        {t("listSearchResults")}
                                    </Typography>
                                </Grid>
                                <List sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper'}}>
                                    {arrayData.map((item,index) => (
                                        <ListItem 
                                            key={item.objects[0].id}   
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => selectObject(item)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            }  
                                        >
                                            <ListItemText
                                                primary= {getDevice(item)}
                                                secondary={
                                                <React.Fragment>
                                                    <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                    >
                                                        {`${getDateTimeFormat(item?.objects[0]?.firstDetectedTime)} - ${getDateTimeFormat(item?.objects[0]?.lastDetectedTime)}`}
                                                    </Typography>
                                                </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box> 
                        )}
                    </Grid>
                </div>
            </Box>
        </FancyPaper>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: 400 }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img
                        style={{height:200, width:200}}
                        srcSet={informationObject?.image}
                        src={informationObject?.image}
                        alt={`image-${informationObject?.objects[0]?.id}`}
                        loading="lazy"
                    />
                    <h2 >{getDevice(informationObject)}</h2>
                    <p style={{ justifyContent:"center", alignItems:"center"}}>
                        {`${getDateTimeFormat(informationObject?.objects[0]?.firstDetectedTime)} - ${getDateTimeFormat(informationObject?.objects[0]?.lastDetectedTime)}`}
                        <br/>
                        {`${t('duration')}: ${millisecondsToMinutes(informationObject?.objects[0]?.lastDetectedTime - informationObject?.objects[0]?.firstDetectedTime)} ${t('minutes')}`}
                    </p>
                
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                        mt: 2,
                        mr: 2,
                        }}
                        onClick={handleClose}
                        autoFocus
                    >
                        {t("close")}
                    </Button>{" "}
                </Grid>
            </Box>
        </Modal>
        </>
    )
}

export default Search;