import { useState, useEffect, useMemo, forwardRef, Fragment } from "react";
import {
  Link,
  LinkProps,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  CssBaseline,
  MenuItem as MenuItemMui,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CardContent,
  Card,
  Stack,
  useMediaQuery,
  Dialog,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import theme from "../theme/theme";
import { useApp } from "../hooks/useApp";
import MessageManager from "../utils/MessageManager";
import { CONSTANT } from "../utils/Constants";
import Loading from "../utils/Loading";
import { useTranslation } from "react-i18next";
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import { AccountCircle, Person } from "@mui/icons-material";
import { BitcodeLogoWhite, FromBracketIcon, GearIcon, HouseIcon } from "../components/svg/icons/SvgIcons";
import { sendMessageContactUs } from "../services/users/UserService";
import ContactUsModal from "../components/util/ContactUsModal";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SearchIcon from '@mui/icons-material/Search';
import CropIcon from '@mui/icons-material/Crop';
import { Color } from "ag-charts-community/dist/types/src/sparklines-util";

const drawerWidth = 264;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.primary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const IconNestedLink = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  "&:before": {
    content: '"→"',
    display: "inline-block",
    left: "0",
    position: "relative",
    transform: "translateX(0px)",
    transition: "all 0.1s ease 0s",
  },
}));

const ListItemTextNested = styled(ListItemText)(({ theme }) => ({
  "& span": {
    fontSize: "80%",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  padding: "0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
}));

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} color="greyDue.main" />
      )),
    [to]
  );

  return <ListItem {...props} component={to ? renderLink : "button"} />;
}

const filterByPermissions = (items: any[], permission: string): any[] => {
  const elements = items.filter((item) => item.permissions.includes(permission));
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByPermissions(el.children, permission),
      };
    }
  });
  return elements;
};

const DashboardHome = () => {
  const { t } = useTranslation();
  const {
    isSuperAdmin,
    authInfo,
    errorMsg,
    successMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,   
    setDomainInfo,
    menuCountingArea,
    loginMotorola,
    handleDomainChange,
    language,
    handleLangChange,
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authMotorolaInfo,
    totalCountingArea,
    totalDevice,
    modalData,
    setModalData,
  } = useApp();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItemCountingArea, setMenuItemCountingArea] = useState<any[]>([]);

  const location = useLocation();
  const menuItems = getMenuItems();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(menuItems.length).fill(false)
  );

  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  
  const [domain, setDomain] = useState<any>(null);

  const handleChangeDomain = async (event: any) => {
    setDomain(event.target.value);
    await handleDomainChange(event.target.value);
  };

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      (item) =>
        !!item.expand &&
        !!item?.children?.find((child: any) => child.to === location.pathname)
    );
    if (selectedMenuItemIndex > -1) {
      setExpanded(
        expanded.map((el, i) => {
          if (i === selectedMenuItemIndex) {
            return !el;
          }
          return el;
        })
      );
    }
  }, []);

  function getMenuItems() {
    let menuItems = [
      {
        label: t("home"),
        icon: (
          <HouseIcon
            sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
          />
        ),
        to: "home",
        permissions: ["is_superuser","is_admin"],
      },
      {
        label: t("search"),
        icon: <SearchIcon />,
        to: "search",
        permissions: ["is_superuser","is_admin"],
      },
      {
        label: t("countingArea"),
        icon: <CropIcon />,
        to: null,
        expand: true,
        children: menuItemCountingArea,
        permissions: ["is_superuser","is_admin"],
      },
      {
        label: t("setting"),
        icon: (
          <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
        ),
        to: null,
        expand: true,
        children: [
          {
            label: t("users"),
            to: "settinguser",
            permissions: ["is_superuser"],
          }
        ],
        permissions: ["is_superuser"],
      },
      {
        label: t("logout"),
        icon: <FromBracketIcon />,
        to: null,    
        permissions: ["is_superuser","is_admin"],
      },
    ];
    if (!isSuperAdmin) {
      menuItems = filterByPermissions(menuItems, "is_admin");
    }
    return menuItems;
  }

  const handleClick = (index: number, item: any) => {
    if (item.expand) {
      setExpanded(
        expanded.map((o, i) => {
          if (i === index) {
            return !o;
          }
          return o;
        })
      );
    }
  };

  const handleSelected = (item: any) => {
    if(item.to === location.pathname)
      navigate(`/dashboard/${item.to}`, { replace: true });
  };

  useEffect(() => {
    const initFunction = async () =>{
      if (authInfo){
        if (authInfo?.authDomain && authInfo?.authDomain.length > 0) {
            setDomain(authInfo?.authDomain[0]);
            setDomainInfo(authInfo?.authDomain[0]);
            const dataRequest: any = {
              username: authInfo?.username,
              url: authInfo?.authDomain[0].url,
              id: authInfo?.authDomain[0].id
            }

            await loginMotorola(dataRequest);

        }
      }else{
        logoutProvider();
      }
    }

    initFunction();
  }, [authInfo]);
  
  useEffect(() => {
    const initFunction = async () =>{     
      if(menuCountingArea && menuCountingArea.length>0){
        let array: any[] = [];

        menuCountingArea.forEach((element: any) => {
          array.push({
            label: element.name,
            to: "countingArea/"+element.id,
            permissions: ["is_superuser","is_admin"],
          })
        });

        setMenuItemCountingArea(array);
      }
    }

    initFunction();
  }, [menuCountingArea]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };



  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);

      const response: any = await sendMessageContactUs(data);
      
      setSuccessMsg && setSuccessMsg(t("messageSent"));    
      setLoading && setLoading(false);
      handleCancelModal();
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(t("errorSendingEmail"));
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
      event.preventDefault();

      //open modal
      setModalData &&
      setModalData({
          ...modalData,
          modalOpen: true,
          modalType: 'contactus',
          modalObject: null,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
      setModalData &&
      setModalData({
          ...modalData,
          modalOpen: false,
          modalType: "",
          modalObject: null,
      });
  };

  const drawer = (
    <div>
      <Box sx={{ justifyContent: "center", textAlign: "center", mb: theme.spacing(2) }}>
        <BitcodeLogoWhite/>
      </Box>
      <List sx={{ pt: 0}}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {item.label === "Logout" || item.label === "Cerrar sesión" ? (
              <ListItemLink
                button
                onClick={logoutProvider}
                selected={handleSelected(item)}
                to={item.to}
                sx={{
                  padding: ".625rem 1.125rem .625rem 1.725rem",
                }}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: ".8125rem",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.label)} />
              </ListItemLink>
            ) : (
              <>
                <ListItemLink
                  button
                  onClick={() => handleClick(index, item)}
                  selected={handleSelected(item)}
                  to={item.to}
                  sx={{
                    padding: ".625rem 1.125rem .625rem 1.725rem",
                  }}
                >
                  <ListItemIcon
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: ".8125rem",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />

                  {item.expand ? (
                    expanded[index] ? (
                      <ExpandLess sx={{ color: "greyDue.main" }} />
                    ) : (
                      <ExpandMore sx={{ color: "greyDue.main" }} />
                    )
                  ) : null}
                </ListItemLink>
                {item.expand &&  (
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      <Card variant="outlined" sx={{ margin: 1 }}>
                        <CardContent>
                          {item?.children?.map((child: any) => (
                            <Fragment key={child.label}>
                                  <ListItemLink
                                    button
                                    to={child.to}
                                    selected={child.to === location.pathname}
                                  >
                                      <IconNestedLink />
                                      <ListItemTextNested> 
                                        <Typography sx={{ fontSize: 12,textTransform: 'uppercase' }} color="text.secondary" gutterBottom> 
                                          {child.label}                          
                                        </Typography>                                        
                                      </ListItemTextNested>   
                                  </ListItemLink>
                            </Fragment>
                          ))}
                        </CardContent>
                      </Card>
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {isLoading && <Loading />}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                px: 6,
                minHeight: "44px",
                maxHeight: "44px",
                backgroundColor: "#212121"
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <IconHamburger />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {authInfo?.name}
            </Typography>
            <Stack 
              direction="row"
            >
              <FormControl variant="standard" >
                <Select
                  value={language}
                  autoWidth
                  sx={{
                    margin: theme.spacing(1),
                    "& .MuiSelect-select": {
                      minWidth: {
                        xs: "30px!important",
                        md: "70px!important",
                      },
                    },
                  }}
                  onChange={(e: any) =>
                    handleLangChange && handleLangChange(e.target.value)
                  }
                >
                  <MenuItem value="es">{isSm ? "ES" : t("spanish")}</MenuItem>
                  <MenuItem value="en">{isSm ? "EN" : t("english")}</MenuItem>
                </Select> 
              </FormControl>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Person fontSize="small" />
                  </ListItemIcon>
                  {authInfo?.name}
                </MenuItem>
                <MenuItem >
                  <FormControl sx={{ m: 1}}>
                    <InputLabel shrink={true} id="simple-select-label-domain">
                      {t("domain")}
                    </InputLabel>
                    <Select
                      labelId="simple-select-label-domain"
                      label={t("domain")}
                      displayEmpty
                      notched={true}
                      defaultValue={domain}
                      value={domain}
                      onChange={handleChangeDomain}
                    >           
                      {authInfo?.authDomain.map((option:any) => (
                          <MenuItem key={option.id} value={option}>
                          {option.url}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenModal}>
                  <ListItemIcon>
                    <ContactMailIcon fontSize="small" />
                  </ListItemIcon>
                  {t("contactUs")}
                </MenuItem>
                <Divider />
                <MenuItem onClick={logoutProvider}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("logout")}
                </MenuItem>
              </Menu>
            </Stack>
              
          </Toolbar>
          
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Main open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                minHeight: "15px",
                margin: "10px"
              },
            }}
          />
          <Box sx={{ flex: "1 1" }}>
            <Outlet />
          </Box>
          <Footer>
            <Typography variant="body2">
              Copyright 2024. All rights reserved.
            </Typography>
          </Footer>
        </Main>
      </Box>
      {modalData?.modalType === "contactus" && (
          <Dialog
          open={modalData?.modalOpen ? modalData.modalOpen : false}
          onClose={handleCancelModal}
          fullWidth
          >
          <ContactUsModal
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
          />
          </Dialog>
      )}
    </>
  );
};

export default DashboardHome;
