import { FC, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Paper,
  IconButton,
  styled,
  InputAdornment,
  Box,
  useMediaQuery,
  MenuItem,
  Select,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { loginTypes } from "../../types/auth/Login";
import Footer from "../../utils/Footer";
import {SVloginSimpleRequest,
  SVloginCodeRequest,
  SVloginRequest,
} from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";
import MessageManager from "../../utils/MessageManager";
import Loading from "../../utils/Loading";
import { CONSTANT } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { BitcodeLogoWhite } from "../../components/svg/icons/SvgIcons";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const Login: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
    language,
    handleLangChange,
  } = useApp();
  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const validation = Yup.object().shape({
    username: Yup.string()
      .required(t("emailRequired"))
      .email(t("emailInvalid")),
    password: Yup.string().required(t("passwordRequired")),
    code: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<loginTypes>({
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // login 2factor
  // const onSubmit = async (data: any) => {
  //   try {
  //     setLoading && setLoading(true);
  //     //Get two factor code
  //     if (!showCode) {
  //       let request: any = await SVloginCodeRequest(data);
  //       setLoading && setLoading(false);
  //       setShowCode(true);
  //       //setSuccessMsg && setSuccessMsg(request?.message  );
  //       setSuccessMsg &&
  //         setSuccessMsg(
  //           request?.status === 200
  //             ? `${t("sendCodeMsg") + data.username}`
  //             : request?.message
  //         );
  //       return;
  //       //Código enviado al correo ana.vivas@bitcode-enterprise.com
  //     }
  //     //Authenticate user
  //     const loginData = await SVloginRequest(data);

  //     await loginProvider(loginData);
  //     setLoading && setLoading(false);
  //   } catch (error: any) {
  //     setLoading && setLoading(false);

  //     setErrorMsg &&
  //       setErrorMsg(
  //         error.message === "001"
  //           ? t("errorUserOrPass")
  //           : error.message === "401" || error.message === "500"
  //           ? t("codeInvalid")
  //           : properties.com_motorola_label_login_general_error
  //       );
  //   }
  // };

  // login simple
  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);
      
      //Authenticate user
      let loginData: any = await SVloginSimpleRequest(data);

      await loginProvider(loginData);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);

      setErrorMsg &&
        setErrorMsg(
          error.message === "001"
            ? t("errorUserOrPass")
            : error.message === "401" || error.message === "500"
            ? t("codeInvalid")
            : properties.com_motorola_label_login_general_error
        );
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}

      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: -5, py: 4 }}
      >
        <Box>
          <BitcodeLogoWhite />
        </Box>
        <Stack
          direction="row"
          justifyContent="center" // inicio de sesión y cambio de idioma a la derecha
          alignItems="center" // alinear verticalmente los elementos
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
            margin: "auto", // centrar el Stack horizontalmente
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              mt: `${theme.spacing(1)}!important`,
              mb: `${theme.spacing(1)}!important`,
              ml: theme.spacing(2), // Margen izquierdo para ajustar el inicio de sesión
              mr: theme.spacing(7), // espacio a la derecha del inicio de sesión
            }}
          >
            {t("login")}
          </Typography>

          <Select
            value={language}
            autoWidth
            sx={{
              margin: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              "& .MuiSelect-select": {
                minWidth: {
                  xs: "30px!important",
                  md: "90px!important",
                },
              },
            }}
            onChange={(e: any) =>
              handleLangChange && handleLangChange(e.target.value)
            }
          >
            <MenuItem value="es">{isSm ? "ES" : t("spanish")}</MenuItem>
            <MenuItem value="en">{isSm ? "EN" : t("english")}</MenuItem>
          </Select>
        </Stack>

        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            // background: "#000000",
            marginBottom: `${theme.spacing(2)}!important`,
            padding: {
              xs: theme.spacing(6),
              md: `${theme.spacing(6)} ${theme.spacing(8)}`,
            },
          }}
        >
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  name={"username"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      autoComplete="username"
                      disabled={showCode}
                      {...register("username")}
                      error={errors.username && Boolean(errors.username)}
                      helperText={errors.username && errors.username.message}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("password")}
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      disabled={showCode}
                      {...register("password")}
                      error={errors.password && Boolean(errors.password)}
                      helperText={errors.password && errors.password.message}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} hidden={!showCode}>
                <Controller
                  name={"code"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      label={t("code")}
                      size="small"
                      type="text"
                      variant="standard"
                      {...register("code")}
                      error={!!errors.code}
                      helperText={!!errors.code && errors.code?.message}
                      inputProps={{ autoComplete: "off" }}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  data-name="update"
                  name="update"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    borderRadius: "0.25rem",
                    mt: 3,
                  }}
                >
                  {t("login")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="rememberpassword">{t("forgetPass")}</LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default Login;
