import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
// import './index.css';
import reportWebVitals from './reportWebVitals';
import { appRoutes } from './routes/AppRoutes';
import theme from "./theme/theme";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={appRoutes}/>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
