import { useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Typography, useTheme, Stack, Paper, styled, Box, MenuItem, Select, useMediaQuery } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { CONSTANT } from "../../utils/Constants";
import { SVActiveUserRequest } from "../../services/security/SecurityService";
import MessageManager from "../../utils/MessageManager";
import Loading from "../../utils/Loading";
import TitleForm from "../../components/TitleForm";
import { useTranslation } from "react-i18next";
import { BitcodeLogoWhite } from "../../components/svg/icons/SvgIcons";
import Footer from "../../utils/Footer";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const Span = styled("span")(({ theme }) => ({
  color: "#326267",
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const ActiveRegister = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
    language,
    handleLangChange,
  } = useApp();
  const { code, email } = useParams();
  const [active, setActive] = useState(false);
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  useLayoutEffect(() => {
    const process = async () => {
      await handleActiveUser();
    };
    process();
    // eslint-disable-next-line
  }, []);

  const handleActiveUser = async () => {
    setLoading && setLoading(true);
    try {
      const emailText: string = email? email:'';
      const codeText: string = code? code:'';
      let responseData: any = await SVActiveUserRequest(emailText, codeText);
      if (!responseData && responseData?.info !== CONSTANT.STATUS_INFO_ACTIVE) {
        setLoading && setLoading(false);
        setErrorMsg && setErrorMsg(responseData.message);
        return;
      }
      setLoading && setLoading(false);
      setActive(true);
      setSuccessMsg && setSuccessMsg(responseData.message);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0, py: 4 }}
      >
        <Box>
          <BitcodeLogoWhite />
        </Box>
        <Stack
          direction="row"
          justifyContent="center" // inicio de sesión y cambio de idioma a la derecha
          alignItems="center" // alinear verticalmente los elementos
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
            margin: "auto", // centrar el Stack horizontalmente
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              mt: `${theme.spacing(2)}!important`,
              mb: `${theme.spacing(2)}!important`,
              ml: theme.spacing(2), // Margen izquierdo para ajustar el inicio de sesión
              mr: theme.spacing(7), // espacio a la derecha del inicio de sesión
            }}
          >          
            {t("activateUser")} 
          </Typography>

          <Select
            value={language}
            autoWidth
            sx={{
              margin: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              "& .MuiSelect-select": {
                minWidth: {
                  xs: "30px!important",
                  md: "90px!important",
                },
              },
            }}
            onChange={(e: any) =>
              handleLangChange && handleLangChange(e.target.value)
            }
          >
            <MenuItem value="es">{isSm ? "ES" : t("spanish")}</MenuItem>
            <MenuItem value="en">{isSm ? "EN" : t("english")}</MenuItem>
          </Select>
        </Stack>
        
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            // background: "#F8F5F3",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(8),
              md: `${theme.spacing(8)} ${theme.spacing(12)}`,
            },
          }}
        >
          {/* <TitleForm>{t("activateUser")}</TitleForm> */}
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 3, fontSize: "1rem" }}
          >
            {active ? (
              <>{t("authenticatedActivatedYourAccount")}</>
            ) : (
              <>{t("errorUserActivationProcess")}</>
            )}
          </Typography>

          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="/" sx={{color: theme.palette.info.dark}}>{t("returnToLogin")}</LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default ActiveRegister;
