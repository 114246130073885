import { createContext, useEffect, useMemo, useState } from 'react';
import { requestTypes } from '../types/Context';
import { jwtDecode } from 'jwt-decode';
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { useNavigate } from "react-router-dom";
import { getItem, setAuthData, setItem } from '../utils/LocalStorageManager';
import { SVloginRequest, SVlogoutRequest } from "../services/auth/AuthService";
import { getAuthMotorolaData, setAuthMotorolaData } from "../utils/LocalStorageManager";
import { getAllRequest } from "../services/countingArea/CountingAreaService";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getAllRequestDevices, getAllRequestGroupsDevices, getAllRequestSitesDevices } from '../services/device/DeviceService';

const initialState = {
    setLoading: () => { },
    isLoading: false,
    language: 'es',
    errorMsg: '',
    successMsg: '',
};

const AppContext = createContext<requestTypes>(initialState);

const AppProvider = ({ children, authData }: any) => {
    const [authInfo, setAuthInfo] = useState(authData);
    const [authMotorolaInfo, setAuthMotorolaInfo] = useState<any>(null);
    const [domainInfo, setDomainInfo] = useState<any>(null);
    const [menuCountingArea, setMenuCountingArea] = useState<any[]>([]);
    const [totalCountingArea, setTotalCountingArea] = useState<number>(0);
    const [totalDevice, setTotalDevice] = useState<number>(0);
    const [authenticate, setAuthenticate] = useState(authData !== null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [language, setLanguage] = useState(initialState.language);
    const [isLoading, setLoading] = useState(initialState.isLoading);
    const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
    const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
    const [deviceSites, setDeviceSites] = useState<any[]>([]);
    const [deviceGroups, setDeviceGroups] = useState<any[]>([]);
    const [devices, setDevices] = useState<any[]>([]);
    const [deviceTree, setDeviceTree] = useState<any[]>([]);
    const [lastDomain, setLastDomain] = useState<string>('');
    const [modalData, setModalData] = useState({
        modalOpen: false,
        modalType: "",
        modalObject: null,
    });

    const { t } = useTranslation();
    const delay = 2000;

    const navigate = useNavigate();

    const resetErrorMsg = () => {
        setErrorMsg("");
    };

    const resetSuccessMsg = () => {
        setSuccessMsg("");
    };

    const logoutProvider = async (e: any) => {
        await setAuthData(null);
        setAuthenticate(false);
        const domainLocalObj: any = await getAuthMotorolaData();
        await logoutMotorolaProvider(domainLocalObj); 
        navigate("/");
        //navigate("/", { replace: true });
    };

  const loginProvider = async (loginData: any) => {
    try {
      const sessionCode = loginData.authCode;
      const email = loginData.username;
      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode
        );
        //save login data in storage
        const authData = await setAuthData(loginDataValidate);
        if (!authData) {
          setAuthenticate(false);
          return;
        }

        const decodeToken: any = await jwtDecode<any>(
          authData?.tokenInfo?.access_token
        );
        const expiresAt = await decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);          
          setIsSuperAdmin(authData.userRole.id === 1);

            navigate("/dashboard/home");
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }

    setAuthenticate(false);
    
  };

  useEffect(() => {
    const initLang = async()=>{
      const localLang: any = await getItem("lang");
      if(localLang)
        handleLangChange(localLang);
    } 

    if (authInfo) {
      setIsSuperAdmin(authInfo.userRole.id === 1);
      initLang();
    }

  }, [isSuperAdmin]);

  /** sesion de motorola */

  const loginMotorola = async (data: any) => {
    try {
        setMenuCountingArea([]);
        setLoading(true);
        const loginData = await SVloginRequest(data);

        await new Promise<void>((resolve) => {
            setTimeout(async () => {
                await loginMotorolaProvider(loginData,data?.url);
                resolve()
            }, delay);
        });

        await loginMotorolaProvider(loginData,data?.url);
        setLoading && setLoading(false);

    } catch (error: any) {
        setLoading && setLoading(false);

        setErrorMsg &&
            setErrorMsg(
                error.message === "001"
                    ? t("errorUserOrPass")
                    : error.message === "401" || error.message === "500"
                        ? t("codeInvalid")
                        : t("loginGeneralError")
            );
    }
  };

  const loginMotorolaProvider = async (loginData: any,urlDomain: string) => {

      try {
          if (loginData?.status === 200 && loginData?.data && loginData?.data['set-cookie'] && loginData?.data['set-cookie'].length > 0) {

              const cookieArray = loginData.data['set-cookie'][0].split(';');

              const authMotorola = {
                  cookie: (cookieArray && cookieArray.length > 0) ? cookieArray[0] : '',
                  urlDomail: urlDomain
              };
              //save login data in storage
              const authData = await setAuthMotorolaData(authMotorola);

              if (!authData) {
                  return;
              }

              setAuthMotorolaInfo(authData);
              return;
          }
      } catch (error) {
          console.log(error);
      }
  };



    /**
    * Metodo encargado de cargar los deviceSites
    * @param data
    */
    const loadAllSites = async () => {
      setLoading && setLoading(true);
      try {
          let responseSites: any = await getAllRequestSitesDevices(authMotorolaInfo);

          if (!responseSites || responseSites?.status !== 200) {
              setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
              setLoading && setLoading(false);
              return;
          }
          
          const data = responseSites?.data;
          data.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name);
          });
          setDeviceSites(data);
          setLoading && setLoading(false);
          loadAllGroups();
      
      } catch (error: any) {
          setErrorMsg && setErrorMsg(error.message);
          setLoading && setLoading(false);
      }
  };

  /**
   * Metodo encargado de cargar los deviceGroups
   * @param data
   */
  const loadAllGroups = async () => {
      setLoading && setLoading(true);
      try {
          let responseGroups: any = await getAllRequestGroupsDevices(authMotorolaInfo);

          if (!responseGroups || responseGroups?.status !== 200) {
              setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
              setLoading && setLoading(false);
              return;
          }
          
          const data = responseGroups?.data;
          data.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name);
          });
          setDeviceGroups(data)
          setLoading && setLoading(false);
          loadCountingAreas();
      } catch (error: any) {
          setErrorMsg && setErrorMsg(error.message);
          setLoading && setLoading(false);
      }
  };

  /**
   * Metodo encargado de cargar los counting Area
   * @param data
   */
  const loadCountingAreas = async () => {
    setLoading && setLoading(true);
    try {

        let response: any = await getAllRequest(authMotorolaInfo);

        if (!response || response?.status !== 200) {
            setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
            setLoading && setLoading(false);
            return;
        }
        setMenuCountingArea(response?.data);
        setTotalCountingArea(response?.data.length);
        setLoading && setLoading(false);    
        loadAllDevices();
    } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
    }
  };
  
  /**
   * Metodo encargado de cargar registros
   * @param data
   */
  const loadAllDevices = async () => {
    setLoading && setLoading(true);
    try {

        let response: any = await getAllRequestDevices(authMotorolaInfo);

        if (!response || response?.status !== 200) {
            setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
            setLoading && setLoading(false);
            return;
        }

        if (response?.status == 200) {
          const data = response?.data;
          data.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name);
          });
          setDevices(data);
          setTotalDevice(data.length);
        }
        setLoading && setLoading(false);
    
    } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
    }
  };
  
  /**
   * Metodo encargado de crear un arbol de los datos
   * @param data
   */
  const createTree = async () => {
    setLoading && setLoading(true);
    try {

      let tree: any[] = [];

      deviceSites.forEach(site => {
          let countDevSite = 0;
          site.nameChildren = '';
          site.countDevices = 0;
          site.children = [];
          site.countingAreas = [];
          deviceGroups.forEach(group => {
              group.nameChildren = '';
              group.children=[];
              devices.forEach(device => {
                  device.idSiteTree = site.id;
                  device.idGroupTree = group.id;
                  if(group?.id === device?.device_group_id){
                      group.children.push(device);
                  }
              });

              group.nameChildren = `${group.name} (${group.children.length})`;
              if(site?.id === group?.parent_id && group.children.length > 0){
                  site.children.push(group);
                  countDevSite=countDevSite+group.children.length;
              }
          });

          menuCountingArea.forEach(countArea => {
            if(site?.id === countArea?.site_id){
                site.countingAreas.push(countArea);
            }
        });
        site.countDevices = countDevSite;
        site.nameChildren = `${site.name} (${countDevSite})`;
        if(site.children.length > 0)
            tree.push(site);
      });

      setDeviceTree(tree);
      
      setLoading && setLoading(false);
    
    } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
    }
  };

  useEffect(()=>{
      const init = async() => {          
        createTree();
      };
      init();
  },[devices]);

  useEffect(() => {
      const init = async()=>{
          if(authMotorolaInfo && authMotorolaInfo?.cookie && authMotorolaInfo?.urlDomail && authMotorolaInfo?.urlDomail !== lastDomain){
            setDeviceTree([]);
            setLastDomain(authMotorolaInfo?.urlDomail);
            loadAllSites();
          }
      } 
      init();
  },[authMotorolaInfo]);

  const logoutMotorolaProvider = async (domainObj: any) => {
      try {
          setLoading(true);          

          if(domainObj)
              await SVlogoutRequest(domainObj);
          await setAuthMotorolaData( null);
          setLoading && setLoading(false);

      } catch (error: any) {
          setLoading && setLoading(false);
          setErrorMsg &&
              setErrorMsg(
                  error.message === "001"
                      ? t("errorUserOrPass")
                      : error.message === "401" || error.message === "500"
                          ? t("codeInvalid")
                          : t("loginGeneralError")
              );
      }

  };

  /** fin sesion de motorola */

  const handleLangChange = async (lang: string) => {
    setLanguage(lang);
    await setItem("lang", lang);
    const localLang: any = await getItem("lang");
    i18n.changeLanguage(localLang);
  };
  /** fin sesion de motorola */

  const handleDomainChange = async (domainObj: any) => {
    navigate("/dashboard/home");
    const domainLocalObj: any = await getAuthMotorolaData();
    await logoutMotorolaProvider(domainLocalObj);  
    setDomainInfo(domainObj);
    const dataRequest: any = {
      username: authInfo?.username,
      url: domainObj.url,
      id: domainObj.id
    }  
    loginMotorola(dataRequest);
    
  };

    const contextValues: requestTypes = useMemo(() => ({
        authInfo,
        setAuthInfo,
        authenticate,
        setAuthenticate,
        language,
        setLanguage,
        isLoading,
        setLoading,
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        modalData,
        setModalData,
        isSuperAdmin,
        setIsSuperAdmin,
        domainInfo,
        setDomainInfo,
        resetSuccessMsg,
        resetErrorMsg,
        logoutProvider,
        loginProvider,
        authMotorolaInfo,
        setAuthMotorolaInfo,
        menuCountingArea, 
        setMenuCountingArea,
        loginMotorolaProvider,
        loginMotorola,
        logoutMotorolaProvider,
        handleLangChange,
        handleDomainChange,
        totalCountingArea,
        totalDevice,
        deviceTree,
        deviceSites, 
        deviceGroups, 
        devices
    }), [
        authInfo,
        setAuthInfo,
        authMotorolaInfo,
        setAuthMotorolaInfo,
        authenticate,
        setAuthenticate,
        language,
        setLanguage,
        isLoading,
        setLoading,
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        modalData,
        setModalData,
        isSuperAdmin,
        setIsSuperAdmin,
        menuCountingArea, 
        setMenuCountingArea,
        deviceTree,
        devices
    ]);

    return (
        <AppContext.Provider value={contextValues}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider };

export default AppContext;