import localforage from "localforage";

export const setAuthData = async (authData:any) => {
  try {
    const keyName:string = 'authApp';
    await setItem(keyName, authData);
    const value:any = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

export const getAuthData = async () => {
  return await getItem('authApp');
};

export const setAuthMotorolaData = async (authData:any) => {
  try {
    const keyName:string = 'authMotorolaApp';
    await setItem(keyName, authData);
    const value:any = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

export const getAuthMotorolaData = async () => {
  return await getItem('authMotorolaApp');
};

/**
 * Agrega un item al store
 * @param key
 * @param value
 */
export const setItem = async (key:string, value:any) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion");
  });
};

/**
 * busca un item al store
 * @param key
 */
export const getItem = async (key:any) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion");
  });
};

/**
 * Elimina un item al store
 * @param key
 */
export const removeItem = async (key:any) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion");
  });
};
