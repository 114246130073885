//Urls Base
export const UrlBase = {
  base_url: window.REACT_APP_BASE_URL,
  base: window.REACT_APP_CORE,  
  api_context: window.REACT_APP_API_CONTEXT,
  security_appclient_id: window.REACT_APP_SECURITY_APPCLIENT_ID,
  security_appclient_pass: window.REACT_APP_SECURITY_APPCLIENT_PASS,
  security_appuser_id: window.REACT_APP_SECURITY_APPUSER_ID,
  security_appuser_pass: window.REACT_APP_SECURITY_APPUSER_PASS,  
  registration_x_page: window.REACT_APP_REGISTRATION_X_PAGE,
  modal_registration_x_page: window.REACT_APP_MODAL_REGISTRATION_X_PAGE,  
  sesion_timeout: window.REACT_APP_SESION_TIMEOUT,
  sesion_timeout_wait: window.REACT_APP_SESION_TIMEOUT_WAIT,
  nodejs_websocket_base_url: window.NODEJS_WEBSOCKET_BASE_URL,
 
};

export default {
  //Url's de inicio y cierre de sesión
  base_url: `${UrlBase.base_url}`,
  api_context: `${UrlBase.api_context}`,
  security_appclient_id: `${UrlBase.security_appclient_id}`,
  security_appclient_pass: `${UrlBase.security_appclient_pass}`,
  security_appuser_id: `${UrlBase.security_appuser_id}`,
  security_appuser_pass: `${UrlBase.security_appuser_pass}`,
  registration_x_page: `${UrlBase.registration_x_page}`,
  modal_registration_x_page: `${UrlBase.modal_registration_x_page}`,
  certificate_registration_x_page: `${UrlBase.certificate_registration_x_page}`,
  sesion_timeout: `${UrlBase.sesion_timeout}`,
  sesion_timeout_wait: `${UrlBase.sesion_timeout_wait}`,
  nodejs_websocket_base_url: `${UrlBase.nodejs_websocket_base_url}`

}

