import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { properties } from "../../utils/Properties_es";
import Loading from "../../utils/Loading";
import { AppProvider } from "../../context/AppContext";


export const AuthLayout = () => {
    const outlet = useOutlet();
  
    const { authPromise }: any = useLoaderData();
  
    return (
      <Suspense fallback={<Loading />}>
        <Await
          resolve={authPromise}
          children={(authObject) => (
            <AppProvider authData={authObject}>{outlet}</AppProvider>
          )}
        />
      </Suspense>
    );
}