import { CONSTANT } from "./Constants";
export const URL = {
  /** users url */
  
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_SIMPLE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPassword",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",REQUEST_USER_LIST: (page: string, size: string) =>
      CONSTANT.DISPATCHER_USER_PREFIX +
      `/user/?page=${page}&size=${size}`,
    REQUEST_USER_LIST_FILTER: (page: string, size: string) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id: any) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
    REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
    REQUEST_ACTIVATE_USER: (code: string, username: string) =>
      CONSTANT.DISPATCHER_USER_PREFIX +
      `/user/activate?code=${code}&username=${username}`,
    REQUEST_VALIDATE_AVAILABLE_USER: (username: string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/validateUserName?username=${username}`,
    REQUEST_VALIDATE_AVAILABLE_PASSWORD: (userId: number) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/validateNewPassword?userId=${userId}`,
    REQUEST_CONTACT_US: () => CONSTANT.DISPATCHER_USER_PREFIX + `/contactus/send`,

    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
    REQUEST_ROLE: (roleId: number) => CONSTANT.DISPATCHER_USER_PREFIX + `/role/${roleId}`,
    REQUEST_USER_COUNT: () => CONSTANT.DISPATCHER_USER_PREFIX + `/user/count`,
    

  
  /** core urls */

  CORE_LOGIN_REQUEST: CONSTANT.DISPATCHER_CORE_PREFIX + "/auth/login",
  CORE_LOGOUT_REQUEST: CONSTANT.DISPATCHER_CORE_PREFIX + "/auth/logout",
  CORE_DEVICE_REQUEST: CONSTANT.DISPATCHER_CORE_PREFIX + "/devices",
  CORE_COUNTING_AREA_REQUEST: CONSTANT.DISPATCHER_CORE_PREFIX + "/countingAreas",
  CORE_MULTI_TIME_LINE_INFORMATION_REQUEST: CONSTANT.DISPATCHER_CORE_PREFIX + "/multiTimelineInformation",

  /** search urls */
  REQUEST_SEARCH_HISTORY_V13: () => CONSTANT.DISPATCHER_CORE_PREFIX + `/search/historyV13`,
  REQUEST_SEARCH_IMAGE: () => CONSTANT.DISPATCHER_CORE_PREFIX + `/search/image`,

  /** counting area urls */
  REQUEST_COUNTING_AREA_LIST_ALL: CONSTANT.DISPATCHER_CORE_PREFIX + `/countingAreas`,
  REQUEST_COUNTING_AREA_JSON: () => CONSTANT.DISPATCHER_CORE_PREFIX + `/countingAreas/json`,
  REQUEST_COUNTING_AREA_CSV: () => CONSTANT.DISPATCHER_CORE_PREFIX + `/countingAreas/csv`,
  REQUEST_COUNTING_AREA_ID: (id: string) => CONSTANT.DISPATCHER_CORE_PREFIX + `/countingAreas/${id}`,
  REQUEST_DEVICE_LIST_ALL: CONSTANT.DISPATCHER_CORE_PREFIX + `/devices`,
  REQUEST_DEVICE_SITES_LIST_ALL: CONSTANT.DISPATCHER_CORE_PREFIX + `/devices/sites`,
  REQUEST_DEVICE_GROUPS_LIST_ALL: CONSTANT.DISPATCHER_CORE_PREFIX + `/devices/groups`,
  REQUEST_DEVICE_BY_GUID: (guid: string) => CONSTANT.DISPATCHER_CORE_PREFIX + `/devices/${guid}`,
  REQUEST_MULTI_TIME_LINE_INFORMATION_LIST_ALL: CONSTANT.DISPATCHER_CORE_PREFIX + `/multiTimelineInformation`,

 /** Role urls */
//  REQUEST_ROLE: (id: string | null, size: string) =>
//   CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
//   `/role${id ? `/?company=${id}` : ""}&size=${size}`,
// REQUEST_ROLE_BY_ID: (id: string | null) =>
//   CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,
// REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
// REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,
// REQUEST_ROLE_DELETE: (id: string | null) =>
//   CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,

};
