import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { useApp } from "../../hooks/useApp";
import { Box, Button, Grid, Typography,TextField, FormControl, MenuItem, FormControlLabel, Checkbox, FormLabel, FormGroup } from "@mui/material";
import { getByIdRequest, getCsvRequest, getJsonRequest } from "../../services/countingArea/CountingAreaService";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider,
    DateTimePicker} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Chart } from "react-google-charts";
import FancyPaper from "../../components/FancyPaper";
import theme from "../../theme/theme";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-charts-community";
import { Height } from "@mui/icons-material";

const CountingArea = () => {
    const { t } = useTranslation();
    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authMotorolaInfo,
    } = useApp();

    const { idCountingArea } = useParams();
    const [countingAreaObj, setCountingAreaObj] = useState<any>({});

    const [optionsAgChartsLine, setOptionsAgChartsLine] = useState<AgChartOptions>({});
    const [optionsAgChartsBar, setOptionsAgChartsBar] = useState<AgChartOptions>({});
    const [optionsAgChartsPie, setOptionsAgChartsPie] = useState<AgChartOptions>({});

    const [statePerson, setStatePerson] = React.useState({
        boolCountPerson: false,
        boolCountPersonIn: false,
        boolCountPersonMin: false,
        boolCountPersonOut: false,
    });

    const { boolCountPerson, boolCountPersonIn, boolCountPersonMin, boolCountPersonOut} = statePerson;
    
    const handleChangeCheckPerson = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatePerson({
          ...statePerson,
          [event.target.name]: event.target.checked,
        });
    };
    
    const [stateVehicle, setStateVehicle] = React.useState({
        boolCountVehicle: false,
        boolCountVehicleIn: false,
        boolCountVehicleMin: false,
        boolCountVehicleOut: false,
    });

    const { boolCountVehicle, boolCountVehicleIn, boolCountVehicleMin, boolCountVehicleOut} = stateVehicle;
    
    const handleChangeCheckVehicle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateVehicle({
          ...stateVehicle,
          [event.target.name]: event.target.checked,
        });
    };
    
    const [stateChart, setStateChart] = React.useState({
        chartPie: false,
        chartLine: false,
        chartBar: false
    });

    const { chartPie, chartLine, chartBar} = stateChart;
    
    const handleChangeCheckChart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateChart({
          ...stateChart,
          [event.target.name]: event.target.checked,
        });
    };

    const [interval, setInterval] = useState<string>('3600000');
    const [chartType, setChartType] = useState<string>('line');
    const [timeZone, setTimeZone] = useState<string>('');

    const handleChangeInterval = (value: string) => {
        setInterval(value);
    };

    const handleChangeChart = (value: string) => {
        setChartType(value);
    };

    const [dateStart, setDateStart] = React.useState<Dayjs | null>(
        dayjs(new Date()),
    );

    const [dateEnd, setDateEnd] = React.useState<Dayjs | null>(
        dayjs(new Date()),
    );

    const handleChangeDateStart = (newValue: Dayjs | null) => {
        setDateStart(newValue);
    };

    const handleChangeDateEnd = (newValue: Dayjs | null) => {
        setDateEnd(newValue);
    };
    
    /**
    * Metodo encargado de buscar json por id
    */
    const findAllJsonById = async () => {
       setLoading && setLoading(true);

       try {
            const dateStringStart  = dayjs(dateStart).format('YYYY-MM-DDTHH:mm:ss');
            const dateStringEnd  = dayjs(dateEnd).format('YYYY-MM-DDTHH:mm:ss');
            const data: any = {
                start: dateStringStart+'Z',
                end: dateStringEnd+'Z',
                step: interval,
                time_location: timeZone,
                id: idCountingArea,
                cookie: authMotorolaInfo.cookie,
                url: authMotorolaInfo.urlDomail 
            };
           let response: any = await getJsonRequest(data);

           if (!response || response?.status !== 200) {
               setErrorMsg && setErrorMsg(t("errorGettingRecord"));
               setLoading && setLoading(false);
               return;
           }

           if(response.status === 200){
            
                let agChartsSeriesPie : any[] = [];
                let agChartsSeriesLine : any[] = [];
                let agChartsSeriesBar : any[] = [];

                if(boolCountPerson){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countPerson",
                        yName: t("countPerson"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countPerson"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countPerson",
                        yName: t("countPerson"),
                    });
                }
                if(boolCountPersonIn){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countPersonIn",
                        yName: t("countPersonIn"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "countPersonIn",
                            legendItemKey: "datetime",
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countPersonIn",
                        yName: t("countPersonIn"),
                    });
                }
                if(boolCountPersonMin){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countPersonMin",
                        yName: t("countPersonMin"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countPersonMin"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countPersonMin",
                        yName: t("countPersonMin"),
                    });
                }
                if(boolCountPersonOut){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countPersonOut",
                        yName: t("countPersonOut"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countPersonOut"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countPersonOut",
                        yName: t("countPersonOut"),
                    });
                }
                if(boolCountVehicle){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countVehicle",
                        yName: t("countVehicle"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countVehicle"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countVehicle",
                        yName: t("countVehicle"),
                    });
                }
                if(boolCountVehicleIn){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countVehicleIn",
                        yName: t("countVehicleIn"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countVehicleIn"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countVehicleIn",
                        yName: t("countVehicleIn"),
                    });
                }
                if(boolCountVehicleMin){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countVehicleMin",
                        yName: t("countVehicleMin"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countVehicleMin"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countVehicleMin",
                        yName: t("countVehicleMin"),
                    });
                }
                if(boolCountVehicleOut){
                    agChartsSeriesLine.push({
                        type: "line",
                        xKey: "datetime",
                        yKey: "countVehicleOut",
                        yName: t("countVehicleOut"),
                    });

                    agChartsSeriesPie = [
                        {
                            type: "pie",
                            angleKey: "datetime",
                            legendItemKey: "countVehicleOut"
                        }
                    ];

                    agChartsSeriesBar.push({
                        type: "bar",
                        xKey: "datetime",
                        yKey: "countVehicleOut",
                        yName: t("countVehicleOut"),
                    });
                }
                
                response.data.totals.forEach((element: any) => {
                    const rawDate = new Date(element.received);
                    const date = dayjs(rawDate).format('DD/MM/YYYY');
                    const time = dayjs(rawDate).format('HH:mm');

                    if(interval === '86400000')
                        element.datetime = date;
                    else
                        element.datetime = `${date} ${time}`;
                });


                setOptionsAgChartsPie({
                    theme: "ag-default-dark",
                    title: {
                        text: t("chartPie"),
                    },
                    data: response.data.totals,
                    series: agChartsSeriesPie
                });

                setOptionsAgChartsBar({
                    theme: "ag-default-dark",
                    title: {
                        text: t("chartBar"),
                    },
                    data: response.data.totals,
                    series: agChartsSeriesBar
                });

                setOptionsAgChartsLine({
                    theme: "ag-default-dark",
                    title: {
                        text: t("chartLine"),
                    },
                    data: response.data.totals,
                    series: agChartsSeriesLine
                });
           }

           setLoading && setLoading(false);
       
       } catch (error: any) {
           setErrorMsg && setErrorMsg(error.message);
           setLoading && setLoading(false);
       }
    };
    
    /**
    * Metodo encargado de buscar json por id
    */
    const findAllCsvById = async () => {
       setLoading && setLoading(true);

       try {
            const dateStringStart  = dayjs(dateStart).format('YYYY-MM-DDTHH:mm:ss');
            const dateStringEnd  = dayjs(dateEnd).format('YYYY-MM-DDTHH:mm:ss');
            const data: any = {
                start: dateStringStart+'Z',
                end: dateStringEnd+'Z',
                step: interval,
                time_location: timeZone,
                id: idCountingArea,
                cookie: authMotorolaInfo.cookie,
                url: authMotorolaInfo.urlDomail 
            };
           let response: any = await getCsvRequest(data);

           if (!response || response?.status !== 200) {
               setErrorMsg && setErrorMsg(t("errorGettingRecord"));
               setLoading && setLoading(false);
               return;
           }

           if(response.status === 200){

                try {
                    const blob = new Blob([response.data], { type: 'text/csv' });
                    
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Counting Area ${data.start}-${data.end}.csv`);
                    link.click();
                
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('Error downloading file:', error);
                    // Handle download error
                }
           }

           setLoading && setLoading(false);
       
       } catch (error: any) {
           setErrorMsg && setErrorMsg(error.message);
           setLoading && setLoading(false);
       }
    };


    
    /**
    * Metodo encargado de buscar por id
    */
    const findById = async () => {
        setLoading && setLoading(true);
 
        try {
             const data: any = {
                 id: idCountingArea,
                 cookie: authMotorolaInfo.cookie,
                 url: authMotorolaInfo.urlDomail 
             };
            let response: any = await getByIdRequest(data);
 
            if (!response || response?.status !== 200) {
                setErrorMsg && setErrorMsg(t("errorGettingRecord"));
                setLoading && setLoading(false);
                return;
            }
 
            if(response.status === 200 && response.data){
                setCountingAreaObj(response.data);
            }
 
            setLoading && setLoading(false);
        
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const cleanPage = async() => {

        setStatePerson({
            boolCountPerson: false,
            boolCountPersonIn: false,
            boolCountPersonMin: false,
            boolCountPersonOut: false,
        });

        setStateVehicle({
            boolCountVehicle: false,
            boolCountVehicleIn: false,
            boolCountVehicleMin: false,
            boolCountVehicleOut: false,
        });

        setStateChart({
            chartPie: false,
            chartLine: false,
            chartBar: false,
        });

        setOptionsAgChartsPie({
            theme: "ag-default-dark",
            title: {
                text: t("chartPie"),
            },
            data: [],
            series: []
        });

        setOptionsAgChartsBar({
            theme: "ag-default-dark",
            title: {
                text: t("chartBar"),
            },
            data: [],
            series: []
        });

        setOptionsAgChartsLine({
            theme: "ag-default-dark",
            title: {
                text: t("chartLine"),
            },
            data: [],
            series: []
        });
    }

    useEffect(()=>{
        const init = async() =>{
            if(idCountingArea && authMotorolaInfo){
                findById();
                cleanPage();
            }
        }
        init();
    },[authMotorolaInfo,idCountingArea]);

    const rankTime = [
        {
          value: '60000',
          label: `1 ${t('minute')}`,
        },
        {
          value: '300000',
          label: `5 ${t('minutes')}`,
        },
        {
          value: '900000',
          label: `15 ${t('minutes')}`,
        },
        {
          value: '3600000',
          label: `1 ${t('hour')}`,
        },
        {
          value: '14400000',
          label: `4 ${t('hours')}`,
        },
        {
          value: '43200000',
          label: `12 ${t('hours')}`,
        },
        {
          value: '86400000',
          label: `1 ${t('day')}`,
        },
    ];

    const charts = [
        {
          value: 'pie',
          label: t("chartPie"),
        },
        {
          value: 'bar',
          label: t("chartBar"),
        },
        {
          value: 'line',
          label: t("chartLine"),
        }
    ];

    return (
        <>
        <FancyPaper pagetitle={`${t("countingArea")}`}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <Box sx={{ width: '100%', marginLeft: 2}}>
                        <Typography variant="h5" gutterBottom sx={{color: theme.palette.info.dark, textTransform: 'uppercase'}}>
                            {`${t("area")}: ${countingAreaObj?.name}`}
                        </Typography>
                    </Box>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        label={t("initialDate")}
                        value={dateStart}
                        onChange={handleChangeDateStart}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        label={t("finalDate")}
                        value={dateEnd}
                        onChange={handleChangeDateEnd}
                        />
                    </LocalizationProvider>

                    <TextField
                        id="outlined-select-interval"
                        select
                        defaultValue={interval}
                        value={interval}
                        label={t("interval")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeInterval(event.target.value);
                        }}
                    >                    
                        {rankTime.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        id="outlined-select-chart"
                        select
                        defaultValue={chartType}
                        value={chartType}
                        label={t("chart")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeChart(event.target.value);
                        }}
                    >                    
                        {charts.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* <TextField
                        id="outlined-helperText"
                        label={t("timeZone")}
                        defaultValue="America/Mexico_City"
                        helperText="Ejemplo: America/New_York"
                        value={timeZone}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setTimeZone(event.target.value);
                        }}
                    /> */}
                </div>
                <div>
                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend" sx={{color: theme.palette.info.dark,textTransform: 'uppercase'}}>{t("persons")}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountPerson} onChange={handleChangeCheckPerson} name="boolCountPerson" />
                                    }
                                    label={t("countPerson")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountPersonIn} onChange={handleChangeCheckPerson} name="boolCountPersonIn" />
                                    }
                                    label={t("countPersonIn")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountPersonMin} onChange={handleChangeCheckPerson} name="boolCountPersonMin" />
                                    }
                                    label={t("countPersonMin")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountPersonOut} onChange={handleChangeCheckPerson} name="boolCountPersonOut" />
                                    }
                                    label={t("countPersonOut")}
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend" sx={{color: theme.palette.info.dark,textTransform: 'uppercase'}}>{t("vehicles")}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountVehicle} onChange={handleChangeCheckVehicle} name="boolCountVehicle" />
                                    }
                                    label={t("countVehicle")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountVehicleIn} onChange={handleChangeCheckVehicle} name="boolCountVehicleIn" />
                                    }
                                    label={t("countVehicleIn")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountVehicleMin} onChange={handleChangeCheckVehicle} name="boolCountVehicleMin" />
                                    }
                                    label={t("countVehicleMin")}
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={boolCountVehicleOut} onChange={handleChangeCheckVehicle} name="boolCountVehicleOut" />
                                    }
                                    label={t("countVehicleOut")}
                                />
                            </FormGroup>
                        </FormControl>
                        {/* <FormContx */}
                    </Box>
                </div>
                <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        
                        >
                        <Button 
                            variant="contained"  
                            sx={{margin:2}}              
                            onClick={() => {
                                findAllJsonById();
                            }}>{t("generate")}
                        </Button>

                        <Button 
                            variant="contained" 
                            color="secondary"  
                            sx={{margin:2}}                
                            onClick={() => {
                                findAllCsvById();
                            }}>{t("csv")}
                        </Button>
                    </Grid>
                </div>
            </Box>
            <Grid container >

                {chartType === 'pie' && (

                    <Box sx={{ width: '100%', margin: 2}}>
                        <AgCharts options={optionsAgChartsPie} />
                    </Box>
                )}

                {chartType === 'bar' && (
                    <Box sx={{ width: '100%', margin: 2}}>
                        <AgCharts options={optionsAgChartsBar} />
                    </Box>
                )}

                {chartType === 'line' && (
                    <Box sx={{ width: '100%', margin: 2}}>
                        <AgCharts options={optionsAgChartsLine} />
                    </Box>
                )}
            </Grid>
        </FancyPaper>
        </>
    )
}

export default CountingArea;