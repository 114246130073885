import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const getRequestRole = async ( filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);  
    await privateFetch
    .get(
      URL.REQUEST_ROLE_LIST,  
      {params}  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
     
    });
    return responseData;   
};

export const findByIdRequest = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_motorola_label_role_findid_error);
    });
  return responseData;
};