import { Box } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as bussinessman } from '../../../resources/icons/businessman.svg';
import { ReactComponent as chart } from '../../../resources/icons/chart-bar.svg';
import { ReactComponent as angleRight } from '../../../resources/icons/chevron-right.svg';
import { ReactComponent as circleCheck } from '../../../resources/icons/circle-check.svg';
import { ReactComponent as facebook } from '../../../resources/icons/facebook.svg';
import { ReactComponent as file } from '../../../resources/icons/file-import.svg';
import { ReactComponent as folder } from '../../../resources/icons/folder-tree.svg';
import { ReactComponent as config } from '../../../resources/icons/gear.svg';
import { ReactComponent as house } from '../../../resources/icons/house.svg';
import { ReactComponent as instagram } from '../../../resources/icons/instagram.svg';
import { ReactComponent as key } from '../../../resources/icons/key.svg';
import { ReactComponent as linkedin } from '../../../resources/icons/linkedin.svg';
import { ReactComponent as list } from '../../../resources/icons/list-check.svg';
import { ReactComponent as logo } from '../../../resources/icons/logopv.svg';
import { ReactComponent as pen } from '../../../resources/icons/pen.svg';
import { ReactComponent as plus } from '../../../resources/icons/plus.svg';
import { ReactComponent as bracket } from '../../../resources/icons/right-from-bracket.svg';
import { ReactComponent as sorter } from '../../../resources/icons/sorter.svg';
import { ReactComponent as down } from '../../../resources/icons/square-caret-down.svg';
import { ReactComponent as table } from '../../../resources/icons/table.svg';
import { ReactComponent as trash } from '../../../resources/icons/trash.svg';
import { ReactComponent as twitter } from '../../../resources/icons/twitter.svg';
import { ReactComponent as gear } from '../../../resources/icons/user-gear.svg';
import { Mail, Phone, Language, Crop, VideoCameraFront } from "@mui/icons-material";

export const Logo = () => {
  return (
    <SvgIcon component={logo} viewBox='0 0 666 666' sx={{ fontSize: '8rem', width: 150 }} />
  );
};

export const MailIcon = (props:any) => {
  return <SvgIcon {...props} component={Mail} />;
};

export const PhoneIcon = (props:any) => {
  return <SvgIcon {...props} component={Phone} />;
};

export const LanguageIcon = (props:any) => {
  return <SvgIcon {...props} component={Language} />;
};

export const BussinessManIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={bussinessman}
      viewBox='0 0 420 420'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};
export const HouseIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={house}
      viewBox='0 0 576 512'
      sx={{ fontSize: '.8125rem', width: 15 }}
      {...props}
    />
  );
};
export const GearIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={gear}
      viewBox='0 0 640 512'
      sx={{ fontSize: '.8125rem', width: 15 }}
      {...props}
    />
  );
};
export const TableIcon = () => {
  return (
    <SvgIcon component={table} viewBox='0 0 512 512' sx={{ fontSize: '.8125rem', width: 16 }} />
  );
};

export const ListCheckIcon = () => {
  return (
    <SvgIcon component={list} viewBox='0 0 576 512' sx={{ fontSize: '.8125rem', width: 16 }} />
  );
};

export const FromBracketIcon = () => {
  return (
    <SvgIcon component={bracket} viewBox='0 0 512 512' sx={{ fontSize: '.8125rem', width: 16 }} />
  );
};

export const SortertIcon = () => {
  return (
    <SvgIcon component={sorter} viewBox='0 0 616.1 512' sx={{ fontSize: '.8125rem', width: 16 }} />
  );
};

export const TrashIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={trash}
      viewBox='0 0 448 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const FileIcon = () => {
  return (
    <SvgIcon component={file} viewBox='0 0 512 512' sx={{ fontSize: '.8125rem', width: 16 }} />
  );
};

export const PlusIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={plus}
      viewBox='0 0 448 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const KeyIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={key}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const PenIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={pen}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const FolderIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={folder}
      viewBox='0 0 576 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const ConfigIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={config}
      viewBox='0 0 576 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const AngleRightIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={angleRight}
      viewBox='0 0 384 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const CircleCheckIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={circleCheck}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const DownIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={down}
      viewBox='0 0 448 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const ChartIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={chart}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1rem', width: 16 }}
      {...props}
    />
  );
};

export const Facebook = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={facebook}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};
export const Instagram = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={instagram}
      viewBox='0 0 448 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};
export const Linkedin = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={linkedin}
      viewBox='0 0 448 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};
export const Twitter = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={twitter}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};

export const CropIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={Crop}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};

export const VideoCameraFrontIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={VideoCameraFront}
      viewBox='0 0 512 512'
      sx={{ fontSize: '1.625rem', width: 26 }}
      {...props}
    />
  );
};

export const BitcodeLogoWhite = () => {
  return (
    <Box>
      <img src='/bitcode-white.svg' alt='companylogo' style={{
        width: '200px',
        transform: 'translateY(40px)',
        marginBottom: '32px',
      }} />
    </Box>
  );
};

export const BitcodeLogoBlack = () => {
  return (
    <Box>
      <img src='/bitcode-black.svg' alt='companylogo' style={{
        width: '200px',
        transform: 'translateY(40px)',
        marginBottom: '32px',
      }} />
    </Box>
  );
};

export const BitcodeLogoColors = () => {
  return (
    <Box>
      <img src='/bitcode-colors.svg' alt='companylogo' style={{
        width: '200px',
        transform: 'translateY(40px)',
        marginBottom: '32px',
      }} />
    </Box>
  );
};
