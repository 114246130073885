import { createTheme, ThemeProvider } from "@mui/material/styles";


const theme  = createTheme({
  palette: {
    mode: 'dark', // Set the mode to dark
    primary: {
      main: '#3f51b5', // Your primary color
    },
    secondary: {
      main: '#f57c00', // Your secondary color
    },
    text: {
      primary: '#fff', // Text color for light background
    },
    background: {
      default: '#121212', // Background color for dark theme
      paper: '#212121', // Background color for components
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase transformation
        },
      },
    },
  },
});

export default theme


